.actual_price{
    color: red;
    margin-left: 10px;
    text-decoration: line-through;
    font-size: 14px;
} 
.fab-container{
    bottom: 35px!important;
    right: auto!important;
    left: 20px;
}

.customer_details .customer_name .customer_value{
    font-weight: 400!important;
}

.customer_details .customer_address .customer_value{
    font-weight: 400!important;
}

.order_details{
    margin-top: 30px;
}

.order_remove_item{
    margin-right: 20px;
}

.infoslider_with_icons .info-box{
    display: flex!important;
    border-right: 1px solid #e7e7e7;
}
.featured_products_details .rate{
    margin-bottom: 5px;
}
.featured_products_details .product-title{
    margin-bottom: 5px!important;
}
.featured_products_details .product-title-ip{
    margin-bottom: 5px!important;
}

.ant-rate{
    margin-bottom: 5px!important;
}


