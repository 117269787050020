*{
    outline: none;
    text-decoration: none;
}
h1,h2,h3,h4,h5,h6,p{
    margin: 0px;
    padding: 0px;   
    text-transform: inherit;
}
.dashboard-left .block-content ul li:before{
    display: none!important;
}

.dashboard-left .block-content{
    padding: 0!important;
}

.side-navigation-panel-select{
    margin-bottom: 0!important;
}

.side-navigation-panel-select-option{
    padding-top: 0!important;
    padding-bottom: 0!important;
    line-height: 37px!important;
}

.border-pink-500, .hover\:border-pink-500:hover{
    /* border-color: rgba(237,100,166,var(--border-opacity)); */
    /* border-color: inherit!important; */
    border-color: #bd3042!important;
}

.dashboard-left .block-content ul li:hover{
    padding-left: 0px!important;
    color: inherit!important;
    transition: none!important;
}

.actions_btns_list a{
    color: #bd3042!important;
}

.actions_btns_list a:hover{
    color: white!important;
}

/* Address Modal */

.addressModal{
    margin: 0!important;
    display: block!important;
    max-width: 100%!important;
}

.inside_form_fields{
    overflow-x: hidden;
}

.addressModal .inside_form_fields .row, .addressModal .inside_form_fields .address_type, .addressModal .inside_form_fields .both_buttons{
    margin-left: 10px!important;
    margin-right: 10px!important;
}

.addressModal .inside_form_fields .address_type, .addressModal .inside_form_fields .both_buttons{
    padding-left: 10px!important;
    padding-right: 10px!important;
}

.cancel_btn_black{
    border-radius: 3px!important;
    font-size: 14px!important;
    font-weight: 500!important;
}

.address-form{
    padding: 15px;
}

.addressModal .modal-content{
    padding-bottom: 10px;
    border-radius: 5px;
    background: #f9f9f9;
}

.addressModal .adderess_inside{
    background: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

/* Cancel Order Modal */

.border_selected_item{
    border: 2px solid #BD3042!important;
}

.radios label{
    position: relative;
    height: 50px;
    width: 100%;
}

.radios label input[type=radio]{
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 1;
}

.radios label input[type=radio]:checked+span{
    /* border: 1px solid #BD3042; */
    color: #BD3042;
}

.radios label input[type=radio]:checked+span::before{
    content: "";
    width: 18px;
    height: 18px;
    /* background: #0065ff; */
    background: #BD3042;
    border-radius: 50%;
    position: absolute;
    right: -5px;
    top: -9px;
}

.radios label input[type=radio]:checked+span::after{
    content: "";
    display: block;
    width: 5px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    right: 1px;
    top: -5px;
}

.span_hide{
    display: none;
}

.span_show{
    display: block;
}
/* End : Cancel Order Modal */