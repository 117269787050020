*{
    outline: none;
    text-decoration: none;
}
h1,h2,h3,h4,h5,h6,p{
    margin: 0px;
    padding: 0px;   
    text-transform: inherit;
}

.red_price{
    color: #999!important;
    margin-right: 10px;
    text-decoration: line-through;
    font-size: 14px!important;
}
.cat-list li div {
    position: relative;
}

.cat-list li div:after {
    content: '';
    top: 45%;
    left: 22%;
    width: 5.71428571px;
    height: 9.14285714px;
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate( 45deg ) scale(1) translate(-50%, -50%);
    transform: rotate( 45deg  ) scale(1) translate(-50%, -50%);
    opacity: 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    opacity: 0;
}  
.cat-list li {
    position: relative;
}
.product-single-qty .btn.btn-outline::before {
    opacity: 0;
}
.product-single-details .product-single-qty > span.input-group-btn.input-group-prepend {
    opacity: 0;
}

.product-single-details .product-single-qty > span.input-group-btn.input-group-append {
    opacity: 0;
}
.main-content .row > div {
    width: 100%;
}
.filter-price-action {
    margin: -10px 0 30px 0 !important;
}

.filter-price-action button {
    min-width: 105px;
    border: none;
    font-size: 18px;
    line-height: 30px;
}

.sidebar-shop .widget:not(:last-child){
    border-bottom: 1px solid #e7e7e7!important;
}
.pincode-field-warp {
    position: relative;
}
body .adjust-mobile-desktop {
    position: absolute;
    right: 25px;
    top: 2px;
}
body .pincode-field-warp button {
    background-color: #fff;
    height: 38px;
    padding: 0;
    margin-left: 15px;
    text-align: center;
    color: #bd3042;
    width: 62px;
    border: none;
    box-shadow: none;
}
body .product-des__details-delivery form {
    margin: 0 0 0 0px;
}
body .number-input.number-input button {
    width: 2.6rem;
    height: 2.6rem;
}

body .delivery-text-wrap {
    position: relative;
}
body .delivery-text-wrap .delivery-text {
    line-height: 21px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
body .delivery-text img {
    max-width: 14px;
    margin-top: -2px;
    margin-right: 3px;
}
body .delivery-text-wrap {
    position: relative;
    padding: 0 0 0 40px;
}
body .product-des__details-delivery {
    box-shadow: none;
    padding: 0;
}
body .pincode-field-warp input {
    border-radius: 3px;
    border: solid 1px #ebebeb;
    line-height: 36px;
    height: 36px;
    width: 266px;
    padding-right: 62px;
    padding-left: 10px;
    color: #555;
    background-color: #fff;
    background-image: none;
    box-shadow: none !important;
}

/* open modal css*/
.delivery-info-wrap-inner .open {
    position: relative;
    top: -1px;
    left: 0px;
}
.delivery-info-wrap-inner .open .delivery-area {
    text-transform: uppercase;
    line-height: 21px;
    float: left !important;
    font-size: 12px;
    display: flex;
    align-items: center;
}
.delivery-info-wrap-inner .open .delivery-area img {
    max-width: 14px;
    margin-top: 1px;
    margin-right: 3px;
}
.delivery-info-wrap-inner .open .delivery-area .help-text {
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
}
.delivery-info-wrap-inner .open .delivery-area .pincode {
    color: #bd3042;
    font-size: 15px;
    vertical-align: middle;
    font-weight: 700;
    padding-left: 2px;
}
.delivery-info-wrap-inner .open .delivery-change-btn {
    background-color: #fff;
    box-shadow: 0px 2px 3px 0 rgb(0 0 0 / 10%);
    border: solid 1px #bd3042;
    border-radius: 2px;
    font-size: 12px;
    color: #bd3042;
    margin-left: 10px;
    padding: 2px 5px;
}
.delivery-info-wrap-inner .open .delivery-details {
    margin-top: 8px;
    margin-bottom: 8px;
}
.delivery-details .shipping-city-details {
    padding-bottom: 10px;
}
.delivery-details .shipping-city-details .text-style {
    font-weight: 600;
}
.delivery-details li {
    color: #686776;
    line-height: 1.2em;
    font-size: 12px;
}
.delivery-details i {
    margin-right: 6px;
    vertical-align: middle;
}
.delivery-details i svg {
    width: 12px;
    height: 12px;
}
.delivery-info-wrap-inner .open .more-info {
    cursor: help;
}
.delivery-info-wrap-inner .open .info-icon {
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #3f414d;
    height: 18px;
    width: 18px;
    font-style: normal;
    font-size: 12px;
    text-align: center;
    margin-right: 5px;
    color: #000;
}
.delivery-info-wrap-inner {
    display: inline-block;
    width: 100%;
    padding-left: 25px;
}