@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap');


/* theme comman color varible  */

:root {
    --theme-primary-color: #BC3043;
    --theme-primary-dark-color: #8e2431;
    --theme-secondary-color: #222529;
    --black: #000000;
    --white: #ffffff;
    --gray: #555555;
    --light-gray: #cccccc;
    --bg-gray: #f4f4f4;
    /* phtowiz page color code */
    --phytowiz-warning: #d69947;
    --phytowiz-green: #47d6ba;
    --phytowiz-blue: #47a7d8;
    --phytowiz-purple: #474fd6;
    --phytowiz-pink: #ce47d6;
    --phytowiz-violet: #7047d6;
    /* alkaline lonizer page css  */
    --alkaline-magenta: #ed145b;
    --alkaline-success: #41b83e;
    --alkaline-warning: #ff9000;
    --alkaline-danger: #ff0000;
    --alkaline-primary: #00c0ff;
    --alkaline-neon-pink: #cc00ff;
}


/*reset css =============================================================================== */

* {
    margin: 0;
    padding: 0
}

body {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    color: #979797;
    counter-reset: section;
}

a {
    text-decoration: none;
    background-color: transparent;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    color: var(--gray);
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

:focus {
    outline: none;
}

a:focus {
    outline: none;
    text-decoration: none;
    box-shadow: none;
}

a:hover {
    text-decoration: none;
    color: var(--theme-primary-color);
}


/* Headings */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins', sans-serif;
    margin: 0 0 10px;
    line-height: normal;
    font-weight: 700;
    letter-spacing: 0;
}

h1 {
    font-size: 20px;
}

h2 {
    font-size: 18px;
}

h3 {
    font-size: 16px;
}

h4 {
    font-size: 14px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 14px;
}

.bg-primary {
    background-color: var(--theme-primary-color) !important;
}


/* Forms */

fieldset {
    border: 0;
}

legend {
    display: none;
}


/* Table */

table {
    border: 0;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    font-size: 100%;
}

caption,
th,
td {
    vertical-align: top;
    text-align: left;
    font-weight: normal;
}


/* Lists */

ul,
ol {
    list-style: none;
    margin: 0;
}


/* image class  */

img {
    max-width: 100%;
}

label {
    color: #000;
}

/* comman margin padding 30px class =========================================================================  */

.pt-30 {
    padding-top: 30px;
}

.pb-30 {
    padding-bottom: 30px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

/* loader-css */

.loading-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all .5s ease-in-out;
    background: #fff;
    opacity: 1;
    visibility: visible;
    z-index: 999999;
}

.loaded .loading-overlay {
    opacity: 0;
    visibility: hidden;
}

.loader-wrapper {
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    display: block;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
}

.loader-wrapper .loader {
    width: 50px;
    height: 50px;
    background-color: var(--theme-primary-color);
    border-radius: 50%;
}

.loader-wrapper .loader,
.loader-wrapper .loader:after {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.loader-wrapper .loader,
.loader-wrapper .loader:after {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.loader-wrapper .loader:after {
    content: "";
    border-radius: 50%;
    border: 0 solid #fff;
    -webkit-animation: loading 1s ease-out infinite forwards;
    animation: loading 1s ease-out infinite forwards;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

@keyframes loading {
    0% {
        border: 0 solid white;
        transition: all 0.3s ease;
    }

    20% {
        border: 8px solid white;
        width: 0;
        height: 0;
        transition: all 0.3s ease;
    }

    100% {
        border: 8px solid white;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
    }
}

/* comman css =============================================================================== */


/* commann btn css  */

.btn {
    transition: all .3s;
    text-transform: uppercase;
    padding: 18px 42px;
    border-radius: 0;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    line-height: 1.429;
}

.btn,
.btn:hover,
.btn:focus {
    box-shadow: none;
    outline: none;
}

.theme-button {
    background-color: var(--theme-primary-color);
    color: var(--white);
}

.theme-button:hover {
    color: var(--white);
    background-color: var(--theme-secondary-color);
}

.btn-black {
    background-color: var(--black);
    border: 1px solid var(--black);
}

.btn-black:hover {
    background-color: var(--theme-secondary-color);
    border-color: var(--theme-secondary-color);
}

.corner-button {
    padding: 12px 20px;
    border-radius: 5px;
}

/* page title css  */

.section-title {
    margin-bottom: 30px;
}

.section-title h2 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.section-title p {
    font-size: 13px;
    line-height: 0.1em;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #ccc;
}

.section-title p span {
    background-color: #ffffff;
    padding: 0 10px;
}



/* comman background color css  */

.bg-theme-primary {
    background-color: var(--theme-primary-color) !important;
}

.bg-gray {
    background-color: var(--bg-gray) !important;
}

.text-theme-primary {
    color: var(--theme-primary-color) !important;
}

.text-theme-secondary {
    color: var(--theme-secondary-color) !important
}

.text-theme-primary-dark {
    color: var(--theme-primary-dark-color) !important;
}


/* section */

section {
    padding-top: 40px;
    padding-bottom: 50px;
}


/* section-title */

@media (min-width: 1220px) {
    .container {
        max-width: 1200px;
    }
}


/* disable */

.disable {
    opacity: .5 !important;
    pointer-events: none !important;
    background: transparent !important;
    border-color: unset !important;
}

/* slider prev/next arrow css  */
.slick-prev,
.slick-next {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgb(0 0 0 / 30%);
    text-align: center;
}

.nav-big .slick-next:before,
.nav-big .slick-prev:before {
    font-size: 20px;
    color: #fff;
    text-align: center;
}

.nav-big .slick-prev:before {
    margin-left: -2px;
}

.nav-big .slick-next:before {
    margin-right: -2px;
}

body .nav-big .slick-prev {
    left: 15px;
}

body .nav-big .slick-next {
    right: 15px;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    background-color: rgb(0 0 0 / 70%);
}

/* breadcum css =========================================================================  */

.breadcrumb {
    background-color: transparent;
    padding-left: 0;
    margin-bottom: 0;
}

.breadcrumb h2 {
    font-size: 16px;
    color: var(--theme-secondary-color);
    font-family: 'Poppins', sans-serif;
}

.breadcrumb .breadcrumb-item {
    font-size: 11.2px;
    line-height: 24px;
    color: var(--gray);
    font-weight: 600;
}

.breadcrumb .breadcrumb-item a {
    color: var(--gray);
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 11px;
}

.breadcrumb-item+.breadcrumb-item::before {
    padding-right: 11px;
    content: "";
    font-size: 12px;
    font-family: 'porto';
    vertical-align: middle;
    margin: 0;
}


/* pagination */

.pagination {
    color: #706f6c;
    font-size: 14px;
    font-weight: 600;
    font-family: Poppins, sans-serif;
}

.page-item.active .page-link {
    color: var(--white);
    background-color: var(--theme-primary-color);
    border-color: var(--theme-primary-color);
}

.page-item.active .page-link:hover {
    color: var(--white);
    background-color: var(--theme-primary-color);
    border-color: var(--theme-primary-color);
}

.pagination .page-item {
    margin-left: 5px;
}

.pagination .page-link {
    border-color: #cccccc;
    padding: 0 10px;
    line-height: 31px;
    color: #706f6c;
    min-width: 30px;
    text-align: center;
}

.pagination .page-link:hover {
    border-color: var(--theme-primary-color);
    background-color: var(--white);
}

.pagination .page-item.disabled {
    display: none;
}

.page-link:focus {
    box-shadow: none;
}


/* form elements css  */

.form-group {
    margin-bottom: 14px
}

.form-group label {
    margin: 0 0 6px;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 700;
}

.form-control {
    height: 40px;
    margin-bottom: 1rem;
    transition: all .3s;
    border: 1px solid #dfdfdf;
    border-radius: 0;
    background-color: #fff;
    color: var(--theme-primary-color);
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    box-shadow: none !important;
    outline: none !important;
}

textarea.form-control {
    max-width: 100%;
    min-height: 112px;
    resize: none;
}

.product-default .def-number-input {
    width: 100%;
    margin: 0;
    text-align: center;
    display: flex;
    padding: 4px 10px;
    background-color: #bd3042;
    justify-content: space-between;
    align-items: center;
}

body .product-default .def-number-input {
    border: none;
}

.product-default .def-number-input button {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50%;
    margin: 2px 15px;
    border-color: #dae2e6;
    cursor: pointer;
    position: relative;
    z-index: 2;
    padding: 0px !important;
    border: 1px solid;
    color: #fff;
}

.product-default .def-number-input input.quantity {
    width: auto;
    height: 32px;
    padding: 10px 2px;
    color: #fff !important;
    font-size: 1.4rem;
    font-family: Poppins, sans-serif;
    border: none;
    background: transparent;
}

.product-default .def-number-input button:after,
.product-default .def-number-input button:before {
    background: #fff;
}

.wishlist-popup.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

body .active {
    display: block !important;
}

.wishlist-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.wishlist-popup .wishlist-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px 15px;
    min-width: 450px;
}

.wishlist-popup .wishlist-box h2 {
    margin-bottom: 20px;
    color: #222529;
    font-size: 18px;
    font-weight: 600;
    width: 480px;
}

.continue_btns {
    background: #bd3042;
    border-radius: 3px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: inherit;
    font-size: 14px;
    outline: none;
    padding: 10px 20px;
    text-align: center;
    font-weight: 500;
}

.continue_btns:focus {
    background-color: var(--black);
}

.cancel_btns {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    color: #000;
    cursor: pointer;
    font-family: inherit;
    font-size: 14px;
    outline: none;
    padding: 10px 20px;
    text-align: center;
    font-weight: 500;
}

.cancel_btns:focus {
    background: #fff;
    color: #000;
    border: 1px solid #ccc;
}


/* mandatory fild (*) css  */

sup.top_star {
    font-size: 14px;
    line-height: 14px;
    top: -4px;
}

.ant-notification {
    z-index: 9999 !important;
}

/* payment-spinner */

section.first {
    background: rgb(0 0 0/ .5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1555;
    min-height: 350px;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 60px;
}

.first .circular-spinner {
    width: 60px;
    height: 60px;
    border: 10px solid #d2d2d2;
    border-top: 10px solid #e91e63;
    border-radius: 50%;
    animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}



/*header-start*/

.header {
    border-bottom: 1px solid #f4f4f4;
}

.header-top {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.025em;
    line-height: 24px;
    border-bottom: 1px solid rgb(231, 231, 231);
    padding: 7.5px 0px;
}

.header-dropdown ul {
    position: static;
    display: flex;
    padding: 0;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    opacity: 1;
    visibility: visible;
}

.header-dropdown ul li+li {
    margin-left: 18px;
}

.header-dropdown ul li a {
    display: block;
    color: #979797;
    font-size: 11px;
}

header .separator {
    height: 20px;
    border-left: 1px solid rgba(0, 0, 0, 0.04);
    margin: 0 17px;
}

.header-right .social-icons {
    margin-left: auto;
}

.header-top .social-icons a {
    margin-left: 15px;
    font-size: 14px;
    color: #979797;
}

.header-top p {
    color: #979797;
    font-size: 11px;
}

.header-middle {
    padding: 27px 0;
    color: var(--theme-secondary-color);
}

.header-left,
.header-center,
.header-right {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.mobile-menu-toggler {
    border: 0;
    background: transparent;
    color: #222529;
    padding: 10px 5px 10px 0;
    margin-right: 5px;
    font-size: 20px;
    line-height: 0;
    cursor: pointer;
    display: none;
}

.logo {
    max-width: 150px;
}

.header-right.w-lg-max {
    position: relative;
    margin-left: auto;
}

.header-contact {
    align-items: center;
    text-transform: uppercase;
    margin-right: 45px;
    margin-left: 48px;
}

.header-contact img {
    margin-right: 8px;
}

.header-contact h6 {
    margin: 0;
    font-size: 11px;
    line-height: 1.3;
    color: var(--theme-primary-color);
    font-weight: 600;
    letter-spacing: 0;
}

.header-contact h6 a {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 700;
}

.header-contact a {
    display: block;
    font-size: 18px;
    white-space: nowrap;
    color: #222529;
}

.user_log_dp {
    margin-right: 16px;
    min-width: 28px;
}

.cart-dropdown.cart {
    min-width: 50px;
}

.sticky-header .cart-dropdown {
    margin-top: 0;
    margin-bottom: 0;
}

.cart-dropdown .dropdown-menu,
.compare-dropdown .dropdown-menu {
    display: block;
    left: 0;
    top: -999rem;
    width: 300px;
    padding-top: 10px;
    z-index: 100;
    font-size: 1.1rem;
    color: #696969;
    border: 0;
    margin: 0;
    background-color: transparent;
    box-shadow: none;
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s;
}

.cart-dropdown .dropdown-menu {
    right: 0;
    left: auto;
    padding-top: 8px;
    width: 276px;
    z-index: 99;
}

.cart-dropdown .dropdown-menu:before {
    content: '';
    position: absolute;
    top: -12px;
    right: 28px;
    border: 10px solid;
    border-color: transparent transparent var(--white);
}

.dropdownmenu-wrapper {
    position: relative;
    background-color: var(--white);
    padding: 0px 0px 3px 0px;
    box-shadow: 0 5px 8px rgb(0 0 0 / 15%);
}

.login_dp {
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 31%);
    height: auto;
    left: auto;
    right: 0;
    position: absolute;
    top: 0;
    width: 276px;
    background-color: var(--white);
    z-index: 999;
    /* display      : none; */
}

.login_dp ul {
    margin-bottom: 0px;
}

.login_dp li {
    border-bottom: 1px solid #eee;
    cursor: pointer;
    font-weight: 400;
    position: relative;
    text-align: left;
}

.login_dp li a {
    padding: 12px 20px 12px 15px;
    font-weight: 400;
    text-align: left;
    font-size: 14px;
    line-height: normal;
    display: block;
    color: #807b7b;
}

.login_dp li a:hover {
    background-color: #eee;
    color: var(--theme-primary-color);
}

.full_widths {
    width: 100%;
    display: inline-block;
    padding: 10px 10px 5px 10px;
}

.login_dp li::after {
    content: "";
    display: contents;
    position: absolute;
    left: 10px;
    top: 17px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.pro_media {
    width: 50px;
    float: left;
    margin-right: 15px;
}

.pro_media img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.pro_content {
    width: calc(100% - 65px);
    float: left;
}

.pro_content p {
    font-size: 16px;
    line-height: 41px;
}

.header-search-wrapper {
    display: flex;
    display: -ms-flexbox;
    position: absolute;
    right: -2.3rem;
    z-index: 999;
    margin-top: 10px;
    color: #8d8d8d;
    box-sizing: content-box;
    height: 40px;
    border-radius: 5rem;
    border: 5px solid var(--theme-primary-color);
}

.header-search:not(.show) .header-search-wrapper {
    display: none;
}

.header-search .form-control,
.header-search .select-custom {
    background: #f4f4f4;
    margin: 0;
    border: 0;
    color: inherit;
    font-size: 14px;
    height: 100%;
    box-shadow: none;
}

.header-search-inline .form-control {
    min-width: 290px;
    padding: 5px 20px;
    border-radius: 30px;
    font-size: 13px;
}

.box {
    position: relative;
}

.pulse {
    background: var(--white);
    height: 50px;
    width: 50px;
    border: 0px solid var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    box-shadow: 0px 2px 8px -2px rgb(168 43 59);
}

.header-middle .box {
    position: absolute;
    right: 40px;
}

.header-middle .box button.pulse {
    width: 30px;
    height: 40px;
    box-shadow: unset;
    background: transparent;
    font-size: 17px;
}

.header-middle a.p-0 {
    position: absolute;
    right: 16px;
    font-size: 16px;
}

.voiceSearch {
    width: 100%;
    display: inline-block;
    box-shadow: 0 1px 14px rgb(0 0 0 / 30%);
    padding: 15px;
    height: 85px;
    position: fixed;
    top: 0px;
    left: 0;
    right: 0px;
    z-index: 9999;
    background-color: var(--white);
    z-index: 155550;
}

.text_speak,
.icon_speak,
.cross_speak {
    width: 33.33%;
    float: left;
}

.text_speak input {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    padding: 0;
    border: none;
    margin: 0px !important;
}

.div_mf {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cross_speak a {
    text-align: right;
    display: block;
    padding-right: 20px;
    font-size: 20px;
    line-height: 50px;
}

.dropdown-toggle i {
    font-size: 28px;
}

.my_cart_top {
    width: 100%;
    display: inline-block;
    background-color: #000;
    padding: 15px 15px;
}

.my_cart_top p {
    font-size: 16px;
    color: var(--white);
    float: left;
    font-weight: 500;
}

.fix_scroll {
    max-height: 370px;
    overflow-y: auto;
    width: 100%;
    display: inline-block;
}

.product_list_d {
    width: 100%;
    display: inline-block;
    padding: 10px 15px;
    background-color: var(--white);
}

.product_list_d.brder_top {
    border-top: 1px solid #eee;
}

.sub_t {
    width: 50%;
    float: left;
}

.sub_r {
    width: 50%;
    float: right;
    text-align: right;
}

.sub_t p {
    font-size: 14px;
    color: #000;
    line-height: 21px;
}

.sub_t p span {
    display: block;
}

.sub_t p i {
    color: var(--theme-primary-color);
}

.sub_r p {
    font-size: 14px;
    color: #000;
    line-height: 21px;
}

.dropdownmenu-wrapper .start_bootm_pro {
    padding-top: 10px;
}

.items_w_img {
    width: 100%;
    display: inline-block;
}

.dropdown.cart-dropdown.top-index .items_w_img {
    padding: 0 7px;
}

.procut_rows {
    width: 100%;
    display: inline-block;
    padding: 0 0 10px 0px;
}

.left_media {
    max-width: 70px;
    width: 100%;
    float: left;
    margin-right: 10px;
}

.right_media {
    max-width: calc(100% - 80px);
    width: 100%;
    float: left;
}

.available_items p {
    font-weight: 400;
    line-height: 21px;
    color: var(--theme-primary-color);
    font-size: 14px;
}

.main-nav .menu>li.drop-down>a:after {
    content: '\e81c';
    position: static;
    margin-left: 2px;
    font-weight: 400;
    right: 1rem;
    font-family: "porto";
}

p.off_perS {
    background-color: #e27c7c;
    border: 1px solid #e27c7c;
    border-radius: 3px;
    color: var(--white);
    display: inline-block;
    font-weight: 500;
    line-height: 1;
    padding: 4px 8px;
    font-size: 11px;
    width: auto;
    text-transform: none;
    text-align: center;
    margin-bottom: 3px;
    float: left;
}

.bottom_rmv {
    width: 100%;
    display: inline-block;
    margin-top: 5px;
}

.cart_left {
    width: 84px;
    float: left;
    margin-right: 36px;
}

.product-single-qty {
    display: inline-block;
    max-width: 104px;
    vertical-align: middle;
}

.number-input.number-input {
    border: 1px solid #ced4da;
    border-radius: 2px;
    margin-bottom: 0px !important;
}

.items_w_img .def-number-input.number-input {
    border: none !important;
    display: flex;
    align-items: center;
}

.number-input button {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    position: relative;
}

body .number-input.number-input button {
    width: 26px;
    height: 26px;
}

.items_w_img .number-input.number-input button {
    border: 1px solid #dae2e6;
    text-align: center;
    padding: 0px !important;
    border-radius: 100px;
    color: var(--theme-primary-color);
}

.number-input button:before,
.number-input button:after {
    display: inline-block;
    position: absolute;
    content: '';
    height: 2px;
    transform: translate(-50%, -50%);
}

.number-input.number-input button:before,
.number-input.number-input button:after {
    width: 12px;
}

.items_w_img .number-input.number-input button:after,
.items_w_img .number-input.number-input button:before {
    background-color: var(--theme-primary-color) !important;
}

.number-input.number-input button:after {
    font-size: 20px !important;
}

.number-input button.plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
}

.number-input.number-input input[type=number] {
    max-width: 30px;
    padding: 3px;
    border: none;
    border-width: 0 1px;
    font-size: 13px;
    height: 30px;
    color: #495057;
    text-align: center;
}

.badge-circle {
    position: absolute;
    top: 1px;
    right: 0;
    width: 16px;
    border-radius: 50%;
    color: var(--white);
    background: var(--theme-primary-color);
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    height: 16px;
}

.dropdown-arrow .badge-circle {
    top: 0;
    left: 20px;
}

.dropdown-arrow {
    position: relative;
    padding-right: 20px;
}

.dropdown-arrow::before {
    content: "\e81c";
    border: 0;
    margin: -5px 2px 0 11px;
    font-size: 15px;
    font-family: porto;
    width: auto;
    height: auto;
    position: absolute;
    right: 0;
    top: 5px;
}

.items_w_img .number-input.number-input input.quantity {
    border: none !important;
    color: var(--theme-primary-color);
    font-size: 12px;
}

.cart_delete {
    width: 90px;
    float: left;
    position: relative;
    cursor: pointer;
}

.cart_delete::before {
    content: "\f2ed";
    display: block;
    position: absolute;
    left: -25px;
    top: 0;
    font-size: 17px;
    font-family: 'Font Awesome 5 Free';
    line-height: 26px;
}

.cart_middle {
    width: 90px;
    float: left;
    position: relative;
    /* cursor  : pointer; */
}

/* .cart_middle::before {
    content    : "\f2ed";
    display    : block;
    position   : absolute;
    left       : -25px;
    top        : 0;
    font-size  : 17px;
    font-family: 'Font Awesome 5 Free';
    line-height: 26px;
} */

.combine_prices .product-price {
    color: #222529;
    font-size: 13px;
}

.old-price {
    text-decoration: line-through;
    font-size: 13px;
    color: #999;
    margin-right: 8px;
}

.cart_right {
    float: left;
    text-align: right;
    margin-top: 5px;
    width: 93px;
}

.item_sub_check {
    margin: 0 15px 10px 15px;
}

.item_sub_check a {
    display: inline-block;
    width: 100%;
    padding: 10px 15px;
    text-align: left;
    background: var(--theme-primary-color);
    border-radius: 3px;
    border: none;
    color: var(--white);
    cursor: pointer;
    font-family: inherit;
    font-size: 14px;
    outline: none;
    -webkit-appearance: none;
}

.left_check {
    width: 50%;
    float: left;
}

.left_check p {
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
}

.right_check {
    width: 50%;
    float: left;
    text-align: right;
}

.right_check p {
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
}

.sticky-header {
    background: var(--white);
}

header .container,
header .container-fluid {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    -ms-flex-align: center;
    justify-content: space-between;
    -ms-flex-pack: justify;
    position: relative;
}

.header-bottom .container:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    border-top: 1px solid #f4f4f4;
    flex: 0 0 100%;
    -ms-flex: 0 0 100%;
}

.menu,
.menu li,
.menu ul,
.menu ol {
    margin: 0;
    padding: 0;
    list-style: none
}

.menu {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5
}

.menu:after {
    display: block;
    clear: both;
    content: ''
}

.menu .show>ul,
.menu .show>.megamenu {
    opacity: 1
}

.menu li>a {
    display: block;
    padding: .8rem 1.8rem;
    transition: .2s ease-out;
    color: var(--theme-primary-color)
}

.menu li:hover>a,
.menu li.show>a,
.menu li.active>a {
    background: #f4f4f4
}

.menu>li {
    float: left;
    position: relative;
    margin-right: 2.8rem
}

.menu>li>a {
    padding: 1rem 0;
    font-size: 13px;
    font-weight: 400;
    color: #555
}

.menu>li:hover>a,
.menu>li.show>a,
.menu>li.active>a {
    color: #8e2431;
    background: transparent
}

.menu>li>.sf-with-ul:before {
    content: '';
    position: absolute;
    z-index: 1000;
    left: 50%;
    bottom: 0;
    margin-left: -14px;
    border: 10px solid;
    border-color: transparent transparent var(--white);
    opacity: 0;
    transition: opacity ease-in .08s
}

.menu>li.show>.sf-with-ul:before {
    opacity: 1;
    visibility: visible
}

.menu .megamenu {
    display: none;
    position: absolute;
    z-index: 999;
    background-color: var(--white);
    box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.15);
    border: 1px solid #eee;
    border-top: 3px solid var(--theme-primary-color);
    left: 15px;
    padding: 10px 20px;
    width: 970px
}

.menu .megamenu.megamenu-3cols {
    width: 600px
}

.menu .megamenu .row>div {
    padding-top: 15px
}

.menu .megamenu img {
    width: 300px;
    height: 100%;
    object-fit: cover
}

.menu .megamenu .submenu {
    margin: 0;
    padding-top: 0;
    border-top: none;
    display: block;
    position: static;
    box-shadow: none;
    min-width: 0
}

.menu .megamenu .submenu a {
    padding: 7px 8px 8px 0
}

.menu .megamenu .submenu li:hover a {
    text-decoration: underline;
    background: transparent
}

.menu .nolink {
    cursor: default;
    display: inline-block;
    padding-bottom: 6px;
    color: #333
}

.menu ul {
    display: none;
    position: absolute;
    min-width: 200px;
    padding: 5px 0;
    border-top: 3px solid var(--theme-primary-color);
    top: 100%;
    left: 0;
    z-index: 99;
    background-color: var(--white);
    box-shadow: 0 29px 29px rgba(0, 0, 0, 0.1)
}

.menu ul ul {
    top: -5px;
    left: 100%
}

.menu.sf-arrows .sf-with-ul+ul>li {
    position: relative
}

.menu.sf-arrows .sf-with-ul:after {
    position: absolute;
    right: 1rem;
    content: '\e81a';
    font-family: 'porto'
}

.menu.sf-arrows>li>.sf-with-ul:after {
    content: '\e81c';
    position: static;
    margin-left: 5px;
    font-weight: 400
}

.main-nav .menu {
    text-transform: uppercase;
    font-size: 12px
}

.main-nav .menu>li {
    margin-right: 34px
}

.main-nav .menu>li>a {
    font-size: 12px;
    font-weight: 700;
    padding: 19px 0;
    border-top: 3px solid transparent;
    letter-spacing: 0;
}

.main-nav .menu>li:first-child a {
    padding-left: 0
}

.main-nav .menu>li:not(.float-right):last-child,
.main-nav .menu>li:not(.float-right)+li.float-right {
    margin-right: 0
}

.main-nav .menu.sf-arrows ul {
    border-top: none
}

.main-nav .menu>li>ul {
    left: -15px
}

.main-nav .menu .megamenu {
    top: 100%;
    left: -15px;
    border-top: none
}

.main-nav .menu .megamenu img {
    height: 100%;
    object-fit: cover
}

.tip {
    display: inline-block;
    position: relative;
    margin: -2px 0 0 1rem;
    padding: 3px 4px;
    border-radius: 2px;
    color: var(--white);
    font-family: "Open Sans", sans-serif;
    font-size: 9px;
    line-height: 1;
    text-transform: uppercase;
    vertical-align: middle;
    z-index: 1;
}

.tip-new {
    background-color: var(--theme-primary-color);
}

.tip-top {
    position: absolute;
    top: 0;
    left: 50%;
    margin-top: 6px;
    margin-left: -2px;
    transform: translate(-50%);
}

.main-nav .menu .tip-top {
    padding: 2px;
    border-radius: 0;
    left: 85%;
    margin-top: 3px;
}

.tip:before {
    position: absolute;
    top: 50%;
    right: 100%;
    left: auto;
    margin-top: -3px;
    border: 3px solid transparent;
    content: '';
}

.tip-top:before {
    top: 100%;
    right: 60%;
    margin: 0;
}

.tip-new.tip-top:before {
    border-top-color: var(--theme-primary-color);
}

.cart-dropdown .dropdown-toggle::after {
    display: none;
}

.wishlist-icon {
    font-size: 27px;
    margin-right: 22px;
}

.dropdown-arrow:after {
    content: "\e81c";
    border: 0;
    margin: -5px 2px 0 11px;
    font-size: 15px;
    font-family: porto;
    width: auto;
    height: auto;
}

.header-icon {
    font-size: 27px;
    margin-right: 22px;
}

.mobile-menu-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 250px;
    transform: translateX(-100%);
    transition: all .25s;
    background-color: #1D1E20;
    font-size: 1.2rem;
    line-height: 1.5;
    box-shadow: 0.1rem 0 0.6rem 0 rgba(50, 50, 50, 0.65);
    visibility: hidden;
    z-index: 1001;
    overflow-y: auto
}

.mmenu-active .mobile-menu-container {
    transform: translateX(0);
    visibility: visible
}

.mobile-menu-container .social-icons {
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0
}

.mobile-menu-container .social-icon {
    width: 30px;
    height: 30px;
    margin-bottom: 0;
    border: 0;
    background-color: transparent;
    font-size: 15px;
}

.mobile-menu-container .social-icon+.social-icon {
    margin-left: .3rem
}

.mobile-menu-container .social-icon:hover,
.mobile-menu-container .social-icon:focus {
    background-color: transparent;
    color: #fafafa
}

.mobile-menu-wrapper {
    position: relative;
    padding: 40px 0 30px;
}

.mobile-menu-overlay {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all .25s;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    z-index: 1000
}

.mmenu-active .mobile-menu-overlay {
    opacity: 1;
    visibility: visible
}

.mmenu-active .sidebar-product {
    display: none
}

.mobile-nav {
    margin: 0 0 5px;
    padding: 0
}

.mobile-menu {
    margin: 0;
    padding: 0;
    list-style: none
}

.mobile-menu li {
    display: block;
    position: relative;
    border-bottom: 1px solid #242527
}

.mobile-menu li a {
    display: block;
    position: relative;
    margin-right: 45px;
    padding: 11px 0 11px 15px;
    color: var(--white);
    font-size: 13px;
}

.mobile-menu li a:hover,
.mobile-menu li a:focus {
    color: #b7b7b7;
    text-decoration: none
}

.mobile-menu li.open>a,
.mobile-menu li.active>a {
    color: #b7b7b7
}

.mobile-menu li ul {
    display: none;
    margin: 0;
    padding: 0
}

.mobile-menu li ul li a {
    padding-left: 2.5rem
}

.mobile-menu li ul ul li a {
    padding-left: 3.5rem
}

.mmenu-btn {
    display: block;
    position: absolute;
    top: 50%;
    right: -38px;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    text-align: center;
    border-radius: 0;
    outline: none;
    background-color: transparent;
    color: var(--white);
    font-size: 15px;
    line-height: 30px;
    cursor: pointer
}

.top-arrowmenu span.mmenu-btn {
    right: 5px;
}

.submenuarrow span.mmenu-btn {
    right: 5px;
}

.mmenu-btn:after {
    display: inline-block;
    margin-top: -2px;
    font-family: 'porto';
    content: '\e81c'
}

.open>a>.mmenu-btn:after {
    content: '\e81b'
}

.mobile-menu-close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 4px;
    color: var(--white);
    font-size: 13px;
    line-height: 1;
    cursor: pointer;
    z-index: 9;
}

.search-toggle {
    font-size: 21px;
}

.search_drp {
    width: 95%;
    display: inline-block;
    background-color: var(--white);
    border: 1px solid #f4f4f4;
    position: absolute;
    left: 15px;
    z-index: 99;
}

body .header-right:hover .header-search-wrapper+div#showSearchDiv {
    display: block !important;
}

.searched_heading p {
    background-color: var(--theme-primary-color);
    padding: 15px;
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
}

.search_drp ul {
    max-height: 280px;
    overflow-y: auto;
}

.search_drp ul li {
    width: 100%;
    display: inline-block;
    text-align: left;
    position: relative;
}

.search_drp ul li:nth-child(odd) {
    background-color: #f4f4f4;
}

.search_drp ul li a {
    font-size: 15px;
    padding: 15px;
    display: flex !important;
    align-items: center;
    position: relative;
}

.search_drp ul li a img {
    width: 28px;
    height: 28px;
    float: left;
    margin-right: 10px;
}

.search_drp ul li p.search-title {
    position: relative;
    max-width: 220px;
    overflow: hidden;
    max-height: 17px;
    padding: 0px 0px 0 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0;
}

.brder_bt {
    position: absolute;
    text-align: right;
    right: 15px;
}

.cart-dropdown .dropdown-menu.show,
.compare-dropdown .dropdown-menu.show {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.header_active .header-middle,
.header_active .header-bottom {
    position: fixed;
    right: 0 !important;
    left: 0 !important;
    z-index: 99 !important;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    top: -40px !important;
    -webkit-transform: translateY(60px);
    transform: translateY(40px);
    width: 100%;
    background: #fff;
}

.header_active .header .header-bottom {
    top: 0 !important;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: translateY(20px);
    transform: translateY(80px);
    z-index: 8 !important;
    -webkit-box-shadow: 0px -1px 5px 0px rgb(0 0 0 / 32%);
    -moz-box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.32);
    -o-box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.32);
    box-shadow: 0px -1px 5px 0px rgb(0 0 0 / 32%);

}

.cart-dropdown .dropdown-menu {
    width: 428px;
}

.user_log_dp .dropdown-menu {
    width: 276px;
}

a.active_signup {
    background: var(--theme-primary-color);
    -webkit-appearance: none;
    border-radius: 3px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: inherit;
    font-size: 14px;
    outline: none;
    padding: 13px 12px;
    text-align: center !important;
    display: block;
    height: 42px;
    margin: 20px auto;
    outline: none;
    width: 236px;
    color: #fff !important;
}

.login_dp li a:hover {
    background-color: #eee;
    color: var(--theme-primary-color) !important;
}


/* header-end */


/* home-page-slider */

body .nav-big .slick-prev {
    left: 30px;
    z-index: 6;
    opacity: 1;
}

body .nav-big .slick-next {
    right: 30px;
    z-index: 6;
    opacity: 1;
}

.nav-big .show-nav-hover.nav-big button.slick-arrow {
    margin: 0 15px;
}

.nav-big .slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.nav-big .slick-next:before {
    content: "\e81a";
    font-family: "porto" !important;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-weight: 900;
    font-size: 30px;
    color: #000;
}

.nav-big .slick-prev:before {
    content: "\E819";
    font-family: "porto" !important;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-weight: 900;
    font-size: 30px;
    color: #000;
}


/* home-page-slider-end */


/* info-slider-home */

.info-box {
    display: flex;
    align-items: center;
    width: 100%;
    color: #222529;
}

.info-box i {
    color: inherit;
    font-size: 37px;
    text-align: center
}

.info-box i:before {
    width: auto;
    margin: 0 1px
}

.info-box h4 {
    color: inherit;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}

.info-box p {
    margin-bottom: 0;
    font-size: 13px;
    letter-spacing: 0.3px;
    line-height: 1.6;
    color: var(--theme-primary-color);
}

.info-box-icon-left {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    -ms-flex-align: center;
    justify-content: center;
    -ms-flex-pack: center
}

.info-box-icon-left i {
    line-height: 0;
    margin-right: 14px
}

.info-box-content a {
    color: var(--theme-primary-color);
}


/* info-slider-home-end */


/* featured-products-section */

.featured-products-section {
    background: var(--white);
}

.CategorySlider-content .product-category {
    color: #1d2127;
    position: relative;
    padding: 0 10px;
}

.product-category a:hover {
    color: inherit
}

.product-category figure {
    margin-bottom: 0;
    position: relative
}

.product-category figure:after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: transparent;
    transition: all 0.3s;
    z-index: 1;
    content: ''
}

.product-category:hover figure:after {
    background-color: rgba(27, 27, 23, 0.15)
}

.product-category img,
.product-category figure:after {
    border-radius: 50%;
}

.product-category:hover figure:after {
    background-color: rgba(27, 27, 23, 0.15);
}

.category-content {
    padding: 20px;
    display: flex;
    display: -ms-flex-box;
    flex-direction: column;
    -ms-flex-direction: column;
    align-items: center;
    -ms-flex-align: center;
    width: 100%;
    background-color: var(--white);
    padding-bottom: 0;
}

.category-content h3 {
    font-weight: 700;
    font-size: 15px;
    line-height: 1.35;
    font-family: "Open Sans", sans-serif;
    letter-spacing: -.5px;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #1d2127;
}

.category-content span {
    font-weight: 400;
    font-size: 10.2px;
    line-height: 1.8;
    font-family: "Open Sans", sans-serif;
    letter-spacing: normal;
    margin-top: -10px;
    text-transform: uppercase;
    opacity: .7
}

.category-content span mark {
    padding: 0;
    background-color: transparent;
    color: inherit
}

.CategorySlider-content .product-category .category-content {
    position: relative;
    margin: -50px 0 0 0;
    z-index: 15;
}


/* featured-products-section-end */


/* promo-section */

.promo-section {
    padding: 70px 0;
    background-size: cover;
}

.promo-section .parallax-background {
    background-color: #22252A
}

.promo-section h2 {
    font-size: 22px;
    line-height: 1.15
}

.promo-section h4 {
    font-size: 11.2px;
    line-height: 1.4
}

.get_fred {
    display: inline-block;
    padding: 3px 8px;
    font-size: 24px !important;
    background-color: #ff7272;
    color: var(--white);
    line-height: 30px;
}

.promo-section h5 {
    font-size: 24px;
    font-family: "Open Sans", sans-serif;
    color: var(--white);
}

.promo-section h5 b {
    font-weight: 700;
}

.coupon-sale-text {
    position: relative;
    display: inline-block;
    padding: 5px 8px;
    transform: rotate(-2deg);
}

.coupon-sale-text b {
    display: inline-block;
    padding: 5px 8px;
    font-size: 1.6em;
    background-color: var(--white);
    color: #222529;
    font-weight: 600;
}

.promo-section .right-content {
    line-height: normal;
}

.promo-section .right-content span {
    color: var(--white);
    font-size: 21px;
}

.promo-section .right-content p {
    font-size: 16px;
    color: var(--white);
}

.promo-section .right-content .bth_de {
    background-color: #ff7272;
    padding: 0 10px 0px 10px;
    font-size: 28px !important;
    line-height: 42px;
    color: var(--white);
    margin-top: 10px;
    display: inline-block;
    font-weight: 600;
    line-height: 34px;
}


/* product-box-css */

.product-default {
    color: var(--theme-primary-color);
    margin-bottom: 20px;
    border: 1px solid #f0f0f0;
}

.product-default:hover {
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 10%);
}

.product-default figure {
    position: relative;
}

.product-default a {
    color: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0;
}

.product-default figure .search_top {
    top: 52% !important;
    left: 44% !important;
    right: auto !important;
    position: absolute;
}

.product-default figure .btn-quickview {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 0 5px;
    width: 36px;
    height: 36px;
    background-color: var(--white);
    border: solid 1px #ddd;
    color: var(--black);
    position: absolute;
    bottom: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
}

.product-default:hover figure .btn-quickview {
    background-color: var(--white);
    border-color: #ddd;
    color: black;
    visibility: visible;
    opacity: 1;
}

.product-default figure .product-action {
    color: #333;
    text-align: center;
    width: 100%;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .3s ease-in-out !important;
    transition: .3s ease-in-out !important;
    background-color: rgb(189, 48, 66);
}

.product-default:hover .product-action {
    visibility: visible;
    opacity: .85;
}

.product-default figure .product-action .add-cart {
    padding: 10px 5px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    width: 100%;
    float: left;
    color: var(--white);
    text-align: center;
    background-color: var(--theme-primary-color);
    border: none;
}

.product-default .product-detail {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    -ms-flex-direction: column;
    align-items: flex-start;
    -ms-flex-align: center;
    justify-content: center;
    -ms-flex-pack: center;
    padding-left: 10px;
    padding-right: 10px;
}

.product-default .product-detail .category-wrap {
    display: flex;
    display: -ms-flexbox;
    justify-content: space-between;
    -ms-flex-pack: justify;
    align-items: center;
    -ms-flex-align: center;
    width: 100%;
}

.product-default .product-detail .category-list {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.7;
    opacity: .8;
    text-transform: uppercase;
}

.product-default .product-title {
    max-width: 100%;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    letter-spacing: .005em;
    margin-bottom: 0.72rem;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 0;
    width: 100%;
}

.product-default .product-title a {
    font-size: 16px;
    font-weight: 400;
    color: #000;
}

.product-default .product-title a:hover {
    color: var(--theme-primary-color);
}

.product-default .ratings-container {
    line-height: 1;
    margin: 0 0 5px 0;
    cursor: pointer;
    position: relative;
    display: inline-block;
}

.product-default .ratings-container .rate {
    margin-bottom: 5px;
}

.product-default .tooltiptext {
    visibility: hidden;
    position: absolute;
    background-color: #333;
    color: var(--white);
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    letter-spacing: .01em;
    text-align: center;
    padding: 10px 7px;
    z-index: 1;
    opacity: 0;
    transition: opacity .3s;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 13px;
}

.tooltip-top:after {
    content: "";
    position: absolute;
    top: 96%;
    left: 50%;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
}

.product-default .ratings-container:hover span.tooltip-top {
    opacity: 1;
    visibility: visible;
}

.product-default .product-title-ip {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 5px !important;
    color: #222529;
}

.product-default .price-box {
    margin-bottom: 10px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    line-height: 1;
}

.product-default .product-price {
    color: #222529;
    font-size: 18px;
    line-height: .8;
    margin-right: 8px;
}

.product-default .old-price {
    text-decoration: line-through;
    font-size: 14px;
    letter-spacing: .005em;
    color: #999;
    margin-right: 8px;
}

.product-default .percentage-dis {
    font-size: 14px;
    font-weight: 400;
    color: #00a100;
}

.product-default .label-group {
    position: absolute;
    top: 5px;
    left: 5px;
}

.product-default .product-label {
    display: block;
    text-align: center;
    margin-bottom: 0px;
    padding: 5px 11px;
    color: var(--white);
    font-weight: 600;
    font-size: 10px;
    line-height: 1;
    background-color: #e27c7c;
}

.shades_area {
    height: 206px;
    background-color: #eee;
    position: absolute;
    top: 0px;
    right: 0;
    width: 100%;
    padding: 10px;
    display: none;
    height: unset !important;
    bottom: 0 !important;
}

.shades_area.active {
    display: block;
}

.shades_area .product-filters-container.scroll_cont {
    max-height: unset !important;
    overflow: unset;
}

.product-single-filter {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 2px;
    font-size: 14px;
}

.product-single-filter label {
    margin-right: 10px;
    margin-bottom: 0;
    color: var(--theme-primary-color);
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
}

.product-single-filter ul.config-size-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.product-single-filter .config-size-list li:not(:last-child) {
    margin-right: 7px;
}

.config-swatch-list {
    margin: 15px 0 0;
    padding: 0;
    font-size: 0;
    list-style: none;
}

.product-single-filter .config-swatch-list {
    display: inline-flex;
    margin: 0;
}

.product-single-filter .config-swatch-list {
    display: flex !important;
    margin: 0 !important;
    flex-wrap: wrap !important;
}

.detail-color li {
    position: relative;
}

.size_box {
    border: 1px solid #000;
    margin: 0 10px 10px 0;
    background-color: var(--white);
}

.size_box a {
    width: 25px !important;
    display: flex !important;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    border: none !important;
    box-shadow: none !important;
    margin: 0px !important;
}

.shades_area .config-swatch-list li a {
    width: auto !important;
    height: 24px;
    padding: 0 10px !important;
}

.cross_icon {
    position: absolute;
    top: 4px;
    right: 10px;
    color: #000;
}

.details_cart {
    position: absolute;
    bottom: 0px;
    background-color: var(--white);
    width: 100%;
    left: 0;
    right: 0px;
}

.details_cart .left_view {
    width: 49%;
    float: left;
}

.details_cart .right_cart {
    width: 49%;
    float: right;
}

.left_view a,
.right_cart a {
    font-size: 12px;
    line-height: 21px;
    background-color: rgba(189, 48, 66, 0.85);
    color: var(--white);
    text-align: center;
    padding: 8px 7px;
    display: block;
    text-transform: uppercase;
    font-weight: 600;
}

.left_view a:hover,
.right_cart a:hover {
    color: var(--white);
}

.product-single-filter .config-size-list li a {
    min-width: 30px;
    height: 30px;
    border: 1px solid #dae2e6;
    background-color: #f4f4f4;
    color: #21293c;
    font-size: 15px;
    font-weight: 600;
    line-height: 28px;
    display: block;
    text-align: center;
    padding: 0 5px;
}

.product-single-filter h3 {
    color: var(--white);
    font-size: 14px;
    margin: 0;
    display: inline-block;
    border-radius: 3px;
    background-color: #00a100;
    padding: 5px;
    font-weight: 500;
}

.product-single-details .divider {
    border-top: 1px solid #e7e7e7;
    margin: 20px 0;
}

.product-action.custom-design {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.delivery-info-wrap-inner.hide {
    display: none;
}

.add-cart {
    padding: 11px 28px;
    font-size: 14px;
}

.add-cart:before {
    font-size: 18px;
    line-height: 0;
    vertical-align: middle;
    margin-right: 8px;
    font-weight: 900;
}

.product-action.custom-design .add-cart {
    background-color: var(--theme-primary-color);
    border-color: var(--theme-primary-color);
}

.product-action.custom-design .add-cart:hover {
    background-color: var(--dark);
    border-color: var(--dark);
}

a.btn.btn-dark.add-cart.icon-shopping-cart.disable {
    background: #c9c9c9 !important;
    border-color: #c9c9c9 !important;
    pointer-events: none !important;
}

.product-single-share {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
}

.social-icon {
    display: inline-block;
    width: 36px;
    color: var(--white);
    background-color: var(--theme-primary-color);
    font-size: 14px;
    line-height: 34px;
    text-align: center;
    text-decoration: none;
    opacity: 1;
    height: 36px;
}

.product-single-share .social-icon {
    border-radius: 50%;
    border: 2px solid transparent;
    margin: 0.2857em 0.4285em 0.2857em 0;
    text-align: center;
}

.product-single-share .social-icon:not(:hover):not(:active):not(:focus) {
    color: #222529;
    background-color: transparent;
    border-color: #e7e7e7;
}

.social-icon.social-facebook {
    background-color: #3b5a9a;
}

.social-icon.social-twitter {
    background-color: #1aa9e1;
}

.social-icon.social-whatsapp {
    background-color: #25D366;
}

.social-icon.social-linkedin {
    background-color: #0073b2;
}

.social-icon.social-mail {
    background-color: #dd4b39;
}

.social-icons .social-icon:hover,
.social-icons .social-icon:focus {
    color: var(--white) !important;
    text-decoration: none;
    opacity: 1 !important;
}

a.fa.fa-heart {
    display: flex;
    padding: 0 8px;
    color: var(--theme-primary-color) !important;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -.015em;
    text-transform: uppercase;
    white-space: nowrap;
}

a.fa.fa-heart span {
    font-size: 12px;
    font-weight: 700;
    font-family: Poppins, sans-serif;
    letter-spacing: -.015em;
    text-transform: uppercase;
    display: inline-block;
    padding: 0 0 0 7px;
}

.size_guide {
    margin-left: 15px;
}

.size_guide a {
    color: var(--theme-primary-color);
}

.product-single-filter {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
}

.product-single-filter .config-swatch-list {
    display: flex !important;
    margin: 0 !important;
    flex-wrap: wrap !important;
}

.detail-color li {
    position: relative;
}

.detail-color li input[type="radio"] {
    position: absolute;
    top: 0;
    width: 27px;
    left: 5px;
    right: 0;
    bottom: 0;
    height: 27px;
    z-index: 1;
    opacity: 0;
}

.detail-color li input[type="radio"] {
    cursor: pointer;
}

.product-single-container .detail-color input[type="radio"] {
    width: 26px;
    height: 26px;
}

.config-swatch-list li a {
    position: relative;
    display: block;
    width: 26px;
    height: 26px;
    margin: 3px 6px 3px 0;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);
}

p.color-green {
    color: #008000;
    font-weight: 400;
    padding-left: 5px;
}

.whole_cart_left button {
    background: #bd3042;
    color: #fff;
    width: 32px !important;
    border: none;
    padding: 0px !important;
    height: 32px !important;
    border-radius: 100px;
}

.whole_cart_left button:before,
.whole_cart_left button:after {
    color: #fff !important;
    background: #fff !important;
}


/* product-css-end */


/* product-listing-page-start */

#review_list_all .comment-container .comment-avatar {
    flex: none !important;
}

.ant-rate-star-second {
    color: #999999 !important;
    /* color: #fff!important; */
}

.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
    color: #BC3043 !important
}

.styles_closeButton__20ID4 {
    display: block !important;
}

.styles_overlay__CLSq- {
    z-index: 1999 !important;
}

.detail-color li a:after {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    color: #fff;
    font-family: 'porto';
    font-size: 17px;
    line-height: 1;
    content: '\E84E';
    opacity: 0;
    visibility: hidden;
    text-shadow: 0px 0px 2px #000;
}

.detail-color li input:checked+a:after {
    opacity: 1;
    visibility: visible;
}

.detail-color li input[type="radio"] {
    position: absolute;
    top: 0;
    width: 27px;
    left: 5px;
    right: 0;
    bottom: 0;
    height: 27px;
    z-index: 1;
    opacity: 0;
}

.detail-color li {
    position: relative;
}

/* sidebar-shop */

.sidebar-shop {
    font-size: 13px;
    margin-bottom: 50px;
}

.sidebar-shop .top-bar {
    margin: 0px 0 0 0;
    border-top: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-left: 1px solid #e7e7e7;
    padding: 15px;
}

.sidebar-shop .top-bar .filter-price-action {
    margin: 0 !important;
}

.sidebar-shop .top-bar .btn {
    padding: 3px 0.7em;
    font-size: 12px;
    font-weight: 400;
}

.sidebar-shop .widget {
    padding: 15px;
    border: 1px solid #e7e7e7;
}

.sidebar-shop .widget:not(:last-child) {
    border-bottom: 1px solid #e7e7e7 !important;
}

.sidebar-shop .widget-title {
    margin: 0;
    color: #313131;
    font-size: 15px;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    line-height: 1.4;
    text-transform: uppercase;
}

.sidebar-shop .widget-title a {
    display: block;
    position: relative;
    color: inherit;
}

.sidebar-shop .widget-title a:before,
.sidebar-shop .widget-title a:after {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 10px;
    height: 2px;
    margin-top: -1px;
    transition: all .35s;
    background: #222529;
    content: '';
}

.sidebar-shop .widget-title a.collapsed:after {
    transform: rotate(-90deg);
}

.widget.size-box .widget-body {
    max-height: 250px;
    overflow-y: auto;
}

.widget-body.category {
    max-height: 290px;
    overflow-y: auto;
    margin-top: 15px;
}

.widget-body>ul.cat-list.color-f::-webkit-scrollbar,
.widget.size-box .widget-body::-webkit-scrollbar,
.widget-body.category::-webkit-scrollbar {
    width: 5px;
}

.widget-body>ul.cat-list.color-f::-webkit-scrollbar-track,
.widget.size-box .widget-body::-webkit-scrollbar-track,
.widget-body.category::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.widget-body>ul.cat-list.color-f::-webkit-scrollbar-thumb,
.widget.size-box .widget-body::-webkit-scrollbar-thumb,
.widget-body.category::-webkit-scrollbar-thumb {
    background: var(--theme-primary-color);
    border-radius: 10px;
}

.widget-body>ul.cat-list.color-f::-webkit-scrollbar-thumb:hover,
.widget.size-box .widget-body::-webkit-scrollbar-thumb:hover,
.widget-body.category::-webkit-scrollbar-thumb:hover {
    background: var(--theme-primary-color);
}

.checkbox-list .checkbox label {
    margin-bottom: 10px;
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
}

.checkbox-list .checkbox label input[type="checkbox"] {
    position: absolute;
}

.checkbox-list .checkbox label span {
    padding-left: 22px;
    font-weight: 400;
    font-size: 13px;
}

.checkbox-list .checkbox label span::before {
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #c3c2c9;
    background: var(--white);
    border-radius: 2px;
    content: "";
    position: absolute;
    display: block;
}

.checkbox-list .checkbox label span::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: 1;
    width: 8px;
    height: 5px;
    border-color: var(--white);
    border-style: none none solid solid;
    border-width: 2px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.color-checkbox.checkbox-list .checkbox label input[type="checkbox"]:checked+.color-box+span::before,
.checkbox-list .checkbox label input[type="checkbox"]:checked+span::before {
    background-color: var(--theme-primary-color);
    border-color: var(--theme-primary-color);
}

.color-checkbox.checkbox-list .checkbox label input[type="checkbox"]:checked+.color-box+span,
.checkbox-list .checkbox label input[type="checkbox"]:checked+span {
    font-weight: bold;
}

.top-bar .filter-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.filter-box .filter-item {
    cursor: pointer;
    font-size: 12px;
    background-color: #e0e0e0;
    box-shadow: 0 2px 4px 0 hsl(0deg 0% 100% / 50%);
    border-radius: 3px;
    margin: 2px 4px 2px 0;
    overflow: hidden;
    transition: background-color .1s;
    padding: 8px;
    cursor: pointer;
    font-weight: 600;
}

.filter-box .filter-item label {
    margin-bottom: 0;
}

.top-bar .filter-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.price-slider-wrapper {
    padding: 20px 0;
    width: 90%;
    margin: 0 auto;
}

.input-range__track--active {
    background: var(--theme-primary-color);
}

.input-range__slider {
    background: var(--theme-primary-color);
    border-color: var(--theme-primary-color);
}

.price-slider-wrapper span.input-range__label-container {
    font-size: 15px;
    color: #000000;
}

.sidebar-shop .widget-body {
    margin: 15px 0 0 0;
}

.color-checkbox.checkbox-list .checkbox label .color-box {
    height: 16px;
    width: 16px;
    position: absolute;
    left: 23px;
    border: 1px solid transparent;
    border-radius: 30px;
}

.color-checkbox.checkbox-list .checkbox label span {
    padding-left: 46px;
}

.infinite-scroll-component {
    overflow: hidden !important;
}


/* sidebar-shop-end */

.toolbox {
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: space-between;
    -ms-flex-pack: justify;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    line-height: 1.5;
}

.toolbox,
.toolbox-left,
.toolbox-right,
.toolbox-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.toolbox-item.toolbox-sort {
    margin-right: 1.5rem;
}

.toolbox label {
    margin: 1px 10px 0 0;
    color: var(--theme-primary-color);
    font-size: 12px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
}

.select-custom {
    position: relative;
    margin-bottom: 0;
    background: var(--white);
}

.select-custom.small-width {
    max-width: 170px;
}

.toolbox .form-control {
    display: inline-block;
    margin-bottom: 0;
    padding: 0 8px;
    color: var(--theme-primary-color);
}

.toolbox .select-custom .form-control {
    max-width: 160px;
    padding-right: 25px;
    font-size: 12px;
    border-radius: 2px;

}

.toolbox select.form-control:not([size]):not([multiple]) {
    height: 34px;
}

.toolbox select.form-control:not([size]):not([multiple]) {
    height: 34px;
}

.product-single-gallery {
    margin-bottom: 30px;
}

.product-slider-container {
    position: relative;
}

.carousel {
    position: relative;
}

.all-dots {
    display: flex;
    font-style: italic;
    margin: 20px -10px 0 0;
    overflow-x: auto;
}

.all-dots button {
    border: none;
    padding: 0 10px 0 0;
    background: transparent;
    flex: 0 0 25%;
    max-width: 25%;
}

.all-dots button img {
    max-width: 100%;
    border: 1px solid transparent;
}

.all-dots .carousel__dot--selected img {
    border-color: #000000;
}

.carousel-next-previous-btn {
    opacity: 0;
    visibility: hidden;
}

.carousel-next-previous-btn button {
    position: absolute;
    top: 43%;
    transform: translateY(-50%);
    margin: 0;
    left: 0;
    background: transparent;
    border: none;
    font-size: 53px;
    opacity: 1;
    color: #000;
    padding: 0 5px;
}

.carousel-next-previous-btn button.carousel__next-button {
    left: unset;
    right: 0;
}

.styles_modal__gNwvD .product-single-container.product-single-default {
    max-width: 872px;
    margin-bottom: 0;
}

.lg-container.lg-show.lg-show-in {
    z-index: 155555;
    position: relative;
}

.lg-backdrop.in {
    opacity: .8 !important;
}

.product-single-details {
    margin-top: 0;
    margin-bottom: 1rem;
}

.product-single-details .product-title {
    margin-bottom: 5px;
    color: #222529;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -.01em;
    line-height: 36px;
}

.ratings-container {
    line-height: 1;
    margin: 0 0 20px 0;
    cursor: pointer;
    position: relative;
    display: inline-block;
}

.product-single-details .rating-link {
    color: var(--theme-primary-color);
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
}

.product-single-details .short-divider {
    width: 40px;
    height: 0;
    border-top: 2px solid #e7e7e7;
    margin: 0 0 15px;
    text-align: left;
}

.product-single-details .price-box {
    margin-bottom: 20px;
    color: var(--theme-primary-color);
    font-weight: 600;
    font-family: Poppins, sans-serif;
}

.product-single-details .ippoints {
    display: block;
    margin-bottom: 7px;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
}

.product-single-details .product-price {
    font-size: 24px;
    letter-spacing: -.02em;
    vertical-align: middle;
    color: #222529;
    margin-right: 8px;
    font-weight: 700;
}

.product-single-details .old-price {
    font-size: 14px;
    font-weight: 500;
    text-decoration: line-through;
    letter-spacing: .005em;
    color: #999;
    margin-right: 8px;
}

span.percentage-dis {
    color: #00a100;
    font-size: 14px;
    font-weight: 400;
}

.product-single-details .product-desc {
    max-height: 94px;
    overflow: hidden;
    transition: all 0.5s ease;
    margin-bottom: 0;
}

.product-single-details .product-desc.active {
    max-height: inherit;
}

.product-single-details .product-desc p {
    font-size: 14px;
    letter-spacing: -.015em;
    line-height: 24px;
    color: #979797;
}

.product-desc ul {
    padding-left: 18px;
    list-style: outside;
    margin-bottom: 10px;
    color: #979797;
}

.product-desc ul li {
    display: revert;
    margin-bottom: 5px;
    font-size: 15px;
}

.product-single-details .product-desc+button {
    font-size: 14px;
    font-weight: 600;
    color: var(--theme-primary-color);
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--theme-primary-color);
    margin: 0 0 10px 0;
    text-transform: capitalize;
}

.sizechart-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 15555555;
    opacity: 0;
    transition: all 0.5s ease;
    transform: scale(0);
    visibility: hidden;
    padding: 15px;
}

.sizechart-modal .sizechart-bg {
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: .5;
    cursor: pointer;
}

.sizechart-modal .sizechart-img {
    max-width: 640px;
    position: relative;
}

.sizechart-modal .sizechart-img .sizechart-close {
    position: absolute;
    top: -10px;
    background: #bd3041;
    width: 35px;
    height: 35px;
    border-radius: 100px;
    right: -10px;
    color: var(--white);
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sizechart-modal.open {
    transition: all 0.5s ease;
    transform: scale(1, 1);
    opacity: 1;
    visibility: visible;
}

.product-single-tabs {
    margin-bottom: 1rem;
}

.product-single-tabs .nav-tabs .nav-item:not(:last-child) {
    margin-right: 30px;
}

.product-single-tabs .nav-tabs .nav-item .nav-link {
    padding: 12px 0;
    border: 0;
    border-bottom: 2px solid transparent;
    color: #818692;
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    font-family: Poppins, sans-serif;
    letter-spacing: .01rem;
    text-transform: uppercase;
}

.product-single-tabs .nav.nav-tabs .nav-item .nav-link.active {
    color: var(--theme-secondary-color);
    border-bottom-color: var(--theme-secondary-color);
}

.product-single-tabs .tab-pane {
    padding-top: 20px;
    color: #7b858a;
    line-height: 1.92;
}

.product-desc-content ul,
.product-desc-content ol {
    margin-bottom: 20px;
    padding-left: 20px;
    letter-spacing: .005em;
}

.product-desc-content ul li,
.product-desc-content ol li {
    display: revert;
    font-size: 14px;
    list-style: outside;
}

.products-section h2 {
    padding-bottom: 10px;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 24px;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    line-height: 22px;
    letter-spacing: -.01em;
    text-transform: uppercase;
}

.wishlist-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 15;
    transform: translateY(-100%);
    transition: all 0.5s ease;
    opacity: 0;
    visibility: hidden;
    z-index: 155555;
}

.product-action.custom-design {
    display: flex;
    align-items: center;
    padding: 10px 0;
}

.product-single-details .divider+.product-action {
    margin-top: -0.5rem;
}

.product-action.custom-design .def-number-input.number-input {
    margin: 0px;
    width: unset;
    display: flex;
    align-items: center;
    margin-right: 7px;
}

.product-action.custom-design .number-input.number-input button {
    width: 35px;
    font-size: 18px !important;
    padding: 0px;
}

.number-input button:before,
.number-input button:after {
    display: inline-block;
    position: absolute;
    content: '';
    height: 2px;
    transform: translate(-50%, -50%);
    background-color: #495057;
    width: 13px;
}

.number-input.number-input button:after {
    font-size: 20px !important;
}

.product-action.custom-design .number-input.number-input button:after {
    width: 13px;
    height: 2px;
    padding: 0px !important;
}

.product-action.custom-design input.quantity {
    line-height: 45px;
    max-width: unset;
    width: 50px;
    height: unset;
    padding: 0 9px;
    font-size: 17px;
    border: 1px solid #ced4da;
    border-width: 0 1px;
    text-align: center;
    font-weight: 700;
    color: #000;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.sidebar-toggle {
    position: fixed;
    top: 20%;
    left: 0;
    width: 40px;
    height: 40px;
    transition: left .2s ease-in-out 0s;
    border: #dcdcda solid 1px;
    border-left-width: 0;
    background: var(--white);
    font-size: 17px;
    line-height: 38px;
    text-align: center;
    cursor: pointer;
    z-index: 999;
    margin-top: 50px;
}

.sidebar-opened .sidebar-toggle {
    left: 320px;
    z-index: 9000;
}

.sidebar-opened .sidebar-toggle i:before {
    content: "";
}

.add-wishlist {
    display: flex;
    padding: 0 8px;
    color: #222529;
    font-size: 12px;
    font-weight: 700;
    font-family: Poppins, sans-serif;
    letter-spacing: -.015em;
    text-transform: uppercase;
    white-space: nowrap;
}

a.icon-heart.add-wishlist span {
    padding: 0 0 0 7px;
    line-height: 29px;
}

.custom-row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-row span.title {
    font-size: 29px;
    font-weight: 700;
    color: var(--black);
    padding-right: 1%;
}

.custom-row .review-start {
    display: flex;
    align-items: center;
    padding-right: 13%;
}

.custom-row .review-start div {
    background: var(--theme-primary-color);
    color: var(--white);
    display: inline-block;
    padding: 5px 15px 5px 15px;
    border-radius: 20px;
    margin: 0 7px 0 10px;
}

.product-reviews-content .reviews-title {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -.015em;
    margin-bottom: 0;
}

.comment-box {
    line-height: 15px;
}

.comment-container {
    display: flex;
    display: -ms-flexbox;
    padding: 29px 0 8px;
}

.comment-container .comment-avatar {
    flex: 1 0 auto;
    padding: 0 22px 5px 8px;
    flex: none !important;
}

.comment-container .ratings-container {
    margin-bottom: 3px;
    line-height: 12px;
}

.comment-container .comment-info {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 1;
    letter-spacing: -.02em;
}

.comment-container .avatar-name {
    display: inline;
    font-family: inherit;
    font-size: inherit;
}

.comment-container .comment-text p {
    color: #7b858a;
    line-height: 1.92;
    font-size: 14px;
}

.comment-container:not(:first-child) {
    border-top: 1px solid #e7e7e7;
}

.owl-carousel.nav-image-center .owl-nav button {
    top: 35%;
}

.owl-theme .owl-nav [class*='owl-'] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #222529;
    margin: 0;
    font-size: 3rem;
    padding: 4px 7px;
    background: transparent;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
}

.owl-carousel.nav-image-center .owl-nav button.owl-prev {
    left: 10px;
}

.owl-carousel.nav-image-center .owl-nav button.owl-next {
    right: 10px;
}


/* product-listing-page-end */


/* after-login */


/* dashboard */

.dashboard-left .block-content {
    border: 1px solid #eee;
    padding: 0;
}

.side-navigation-panel ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.side-navigation-panel-select-inner-option {
    line-height: 30px !important;
}

.dashboard-left .block-content ul li {
    display: flex;
    transition: all 0.5s ease;
}

ul.side-navigation-panel-select-inner {
    border-bottom: 1px solid #eee;
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
    border-left-width: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1.5rem;
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
    width: 100%;
    border-bottom: 1px solid #ededde !important;
    padding: 8px 15px !important;
    font-size: 14px;
}

.gap_genrate {
    width: 100%;
    padding: 30px 0 30px 0px;
}

.inside_dash {
    background-color: #eee;
    width: 100%;
    display: table;
    border-radius: 8px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 15px;
}

.inside_dash a {
    display: block;
    text-align: center;
}

.inside_dash i {
    font-size: 60px;
    color: #6f6e6e;
    margin-bottom: 10px;
}

.inside_dash a p {
    font-size: 15px;
    color: #000;
    line-height: 24px;
}

.inside_dash .inside_icon-dash {
    position: relative;
    display: inline-block;
}

.inside_dash a:hover i {
    color: var(--theme-primary-color);
}

.inside_dash a:hover p {
    color: var(--theme-primary-color);
}

span.notification_count {
    background-color: var(--theme-primary-color);
    color: var(--white);
    font-size: 15px;
    height: 36px;
    width: 36px;
    display: block;
    border-radius: 30px;
    line-height: 28px;
    font-weight: 600;
    text-align: center;
    position: absolute;
    right: -10px;
    top: 0;
    border: 4px solid #eeeeee;
}

.cart_count span.notification_count {
    right: -20px;
    top: -10px;
}

.my_profile_inside {
    width: 100%;
    display: inline-block;
    background-color: #eee;
    padding: 30px;
    min-height: 598px;
}

ul.side-navigation-panel-select-inner li.side-navigation-panel-select-inner-wrap {
    border-color: #e2e8f0 !important;
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option {
    border-left-width: 2px;
    cursor: pointer;
    display: block;
    font-size: .875rem;
    line-height: 1.25rem;
    padding: 0.5rem 3rem;
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
}


/* dashboard-end */


/* my-account */

.inner_profile_in {
    width: 100%;
    display: block;
    padding: 5px 0px;
    border-bottom: 1px solid #cecece;
    margin-bottom: 10px;
}

.edit_profile_in p,
.inner_profile_in p {
    font-size: 15px;
    color: #000;
    font-weight: 500;
}

.inner_profile_in p span {
    display: block;
    padding: 5px 0px 0px;
    font-weight: 400;
    color: #828080;
}

.allImg {
    display: flex;
    justify-content: space-around;
    margin: 20px 0px;
}

.allImgNo.no-profile-img img,
.allImg img {
    margin: 6px;
    height: 140px;
}

.btn.profile_btn {
    padding: 6px 15px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
}

.my_profile_inside .form-group {
    margin-bottom: 30px;
}

.form-control.forms_inputs {
    height: 35px;
    border: none;
    padding: 0;
    transition: all .3s;
    border-bottom: 1px solid #cecece;
    border-radius: 0;
    background-color: transparent;
    color: #333;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.form-control.forms_inputs::placeholder {
    color: rgb(155, 155, 155);
    font-weight: 400;
    font-size: 14px;
}

.slect_pst {
    background-color: transparent;
    height: 35px !important;
    padding: 7px 0px !important;
    border: none;
    border-bottom: 1px solid #cecece;
    font-size: 16px !important;
    font-weight: 600;
    line-height: 24px;
    color: #333;
    -moz-appearance: none;
}

.mart_status {
    margin-bottom: 20px;
}

.allImgNo {
    margin: 0 0 20px 0px;
    border: 0px solid #000;
    /* padding-top: 20px; */
}

.top_caption_up {
    margin-top: -30px;
}

.gap_top_ot p {
    font-size: 15px;
    color: #000;
    line-height: 27px;
    margin-bottom: 10px;
}

.my_dashboards .card-header {
    margin: 0;
    line-height: 1.5;
    text-transform: uppercase;
    border: none;
    font-size: 16px;
    padding: 16px 20px;
    text-align: left;
    width: 100%;
    background-color: var(--theme-primary-color);
    -webkit-appearance: none;
    color: var(--white);
    font-weight: 400;
    cursor: pointer;
}


/* my-account-end */


/* my-address-start */

.grid_row {
    display: flex;
    align-items: center;
}

.grid_row .grid_content {
    width: calc(100% - 200px);
}

.grid_row .grid_actions {
    margin-left: auto;
    width: 200px;
}

.actions_btns {
    padding: 16px 20px;
    display: flex;
    justify-content: flex-end;
}

.edit_delete {
    float: left;
    width: 20px;
    height: 20px;
}

.actions_btns a {
    padding: 8px;
    text-decoration: none;
    color: var(--theme-primary-color);
    font-size: 14px;
}

.addressList {
    width: 100%;
    display: block;
    padding: 16px 20px 20px 50px;
    position: relative;
}

.addressList h4 i {
    margin-right: 10px;
    font-size: 26px;
    position: absolute;
    left: 10px;
}

.card_bg_color .card-body:nth-child(odd) {
    background-color: #f4f4f4;
}

.my_dashboards .card-body h4 {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 17px;
    color: #333;
    font-weight: 500;
}

.my_dashboards .card-body h5 {
    margin-bottom: 5px;
}

.my_dashboards .card-body {
    min-height: auto;
    border-radius: 0;
    background-color: var(--white);
    border-top: 1px solid #eee;
    padding: 0px;
    position: relative;
}

.addressList address {
    margin-bottom: 0;
}


/* my-address-end */


/* my-order-start */

.order_history table {
    border: 1px solid #eee;
    white-space: nowrap;
}

.order_history table thead tr th {
    text-align: center;
    color: var(--white);
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    vertical-align: middle;
    padding: 15px 10px;
    background-color: var(--theme-primary-color);
}

.order_history table thead tr th:nth-child(1) {
    width: 13%;
}

.order_history table thead tr th:nth-child(2) {
    width: 13%;
}

.order_history table thead tr th:nth-child(3) {
    width: 8%;
}

.order_history table thead tr th:nth-child(4) {
    width: 14%;
}

.order_history table thead tr th:nth-child(5) {
    width: 10%;
}

.order_history table thead tr th:nth-child(6) {
    width: 15%;
}

.order_history table tbody tr:nth-child(2n+1) {
    background-color: #eee;
}

.order_history table tbody tr td {
    text-align: center;
    color: #000;
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    vertical-align: middle;
}

.order_history .badge {
    font-size: 14px;
    font-weight: 600;
    color: var(--white);
    padding: 6px 0;
    border-radius: 4px;
    width: 104px;
}

.badge.status_waiting {
    background-color: #17a2b8;
}

.badge.status_delevered {
    background-color: #28a745;
}

.badge.status_preparing {
    color: #212529 !important;
    background-color: #ffc107;
}

.badge.status_on_the_way {
    background-color: #007bff;
}

.badge.status_cancelled {
    background-color: #ed3035;
}

.badge.status_returned {
    background-color: #6c757d;
}

.actions_btns_list a {
    width: 35px;
    height: 35px;
    border-radius: 60px;
    margin-right: 2px;
    padding: 6px;
    border: 1px solid var(--theme-primary-color);
    background-color: var(--white);
    display: inline-block;
}

.actions_btns_list a:hover {
    background-color: var(--theme-primary-color);
    border: 1px solid #000;
    color: var(--white);
}

.ant-radio-wrapper {
    font-weight: 700;
}

.ant-modal-title {
    font-size: 22px;
    font-weight: 600;
    color: #222529;
}

.inside_crt {
    width: 100%;
    display: flex;
    padding: 0 0 15px 0px;
    flex-wrap: wrap;
}

.inside_crt .media_cart {
    width: 80px;
}

.cancel_order_details .inside_crt .list_iten_cart {
    width: calc(100% - 120px);
    padding: 0 15px;
}

.cancel_order_details .inside_crt .increase_product.circle_mp {
    width: auto;
    margin-left: auto;
}

.cart_content .product-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 8px;
}

.cart_content .product-name a {
    color: var(--theme-primary-color);
}

.cart_content .price-list {
    margin-bottom: 5px;
}

.price-list .product-price {
    font-weight: 600;
}

.price-list .old-price {
    color: #000000;
}

.price-list .off-price {
    color: #00a100;
}

.product-single-qty.circle_mp input {
    position: absolute;
    width: 25px;
    z-index: 1;
    height: 25px;
    opacity: 0;
}

.product-single-qty label.checkbox {
    border: 1px solid var(--theme-primary-color);
    display: inline-block;
    width: 25px;
    height: 25px;
    position: relative;
    background: var(--theme-primary-color);
}

.product-single-qty label.checkbox:after {
    content: "\f00c";
    font-family: 'FontAwesome';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
}

.cancel_btns {
    background: var(--white);
    border: 1px solid #ccc;
    border-radius: 3px;
    color: var(--black);
    cursor: pointer;
    font-family: inherit;
    font-size: 14px;
    outline: none;
    padding: 10px 20px;
    text-align: center;
    font-weight: 500;
}

.cancel_btns:hover {
    border-color: var(--black);
    background-color: var(--black);
    color: var(--white);
}

.top-order-view {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.top-order-view .checkout-title {
    font-size: 14px;
    margin-bottom: 0;
}

.top-order-view .checkout-title span {
    margin-left: 6px;
    color: var(--theme-primary-color);
}

.top-order-view .order_status .badge {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    padding: 6px 0;
    border-radius: 4px;
    width: 100px;
}

.order-date h4 {
    font-size: 14px;
    font-weight: 400;
}

.order-date h4 span {
    font-weight: 700;
}

.checkout-status {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #dddddd;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dddddd;
}

.checkout-status .checkout-title {
    margin-bottom: 10px;
}

ul.checkout-progress-bar {
    display: flex;
    margin: 0;
}

.checkout-progress-bar {
    display: block;
    margin: 0 0 30px;
    font-size: 0;
    line-height: 1.4;
    counter-reset: i
}

.checkout-progress-bar li {
    display: inline-block;
    position: relative;
    width: 50%;
    margin: 0;
    text-align: center;
    vertical-align: top
}

.checkout-progress-bar li:before {
    position: absolute;
    top: 19px;
    left: 0;
    width: 100%;
    height: 7px;
    transition: background .3s;
    background-color: #e4e4e4;
    content: ''
}

.checkout-progress-bar li>span {
    display: inline-block;
    width: 100%;
    padding-top: 45px;
    color: #ccc;
    font-size: 16px;
    font-weight: 300;
    word-wrap: break-word
}

.checkout-progress-bar li>span:before,
.checkout-progress-bar li>span:after {
    position: absolute;
    top: 0;
    left: 50%;
    transition: background .3s;
    border-radius: 50%;
    content: ''
}

.checkout-progress-bar li>span:before {
    width: 38px;
    height: 38px;
    margin-left: -19px;
    background-color: #e4e4e4
}

.checkout-progress-bar li>span:after {
    top: 6px;
    width: 26px;
    height: 26px;
    margin-left: -13px;
    background: #fff;
    color: #222529;
    font-weight: 600;
    content: counter(i);
    counter-increment: i;
    line-height: 26px;
    ;
}

.checkout-progress-bar li.active>span:after {
    color: #008000;
}

.checkout-progress-bar li.active:before,
.checkout-progress-bar li.active>span:before {
    background-color: #008000;
}

.checkout-progress-bar li.active>span {
    color: #008000;
    font-weight: 700;
}

.checkout-progress-bar li.one.active>span,
.checkout-progress-bar li.two.active+li:nth-child(2)>span,
.checkout-progress-bar li.three.active+li:nth-child(2)>span,
.checkout-progress-bar li.four.active+li:nth-child(2)>span,
.checkout-progress-bar li.three.active+li+li:nth-child(3)>span,
.checkout-progress-bar li.four.active+li+li:nth-child(3)>span,
.checkout-progress-bar li.four.active+li+li+li:last-child>span {
    color: #008000;
    font-weight: 700;
}

.checkout-progress-bar li.active>span:after {
    font-family: 'porto';
    content: '\e84e'
}

.checkout-progress-bar li:first-child:before {
    border-radius: 6px 0 0 6px
}

.checkout-progress-bar li:last-child:before {
    border-radius: 0px 6px 6px 0
}

.customer_add_name {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 20px;
    margin-bottom: 0px;
}

.customer_add_name h4 {
    font-size: 14px;
    font-weight: 400;
}

.shipping_details,
.shipping_address_value {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 20px;
    height: 100%;
}

.checkout-title {
    font-size: 14px;
    margin-bottom: 10px;
}

.cart-table-container p {
    display: block;
    line-height: normal;
    padding: 0 0 3px 0;
}

.summary_details,
.payment_details {
    margin-top: 20px;
    height: 100%;
}

.cart-table-container .summary_details p,
.cart-table-container .payment_details p,
.cart-table-container .shipping_details p {
    margin: 0;
    padding: 0;
    display: flex;
    margin-bottom: 7px;
}

.summary_details p label,
.payment_details p label,
.shipping_details p label {
    display: block;
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    width: 180px;
}

.summary_details p span,
.payment_details p span,
.shipping_details p span {
    display: block;
    font-size: 14px;
    width: calc(100% - 160px);
}

.summary_details p span {
    text-align: right;
}

.summary_details p.total_amount {
    border-top: 3px solid var(--theme-primary-color);
    padding-top: 5px;
}

.summary_details p.total_amount label,
.summary_details p.total_amount span {
    font-weight: 700;
    color: #222529;
}

.items-listing {
    margin-top: 20px;
}

.cart-table-container thead tr th {
    background-color: var(--theme-primary-color);
    color: var(--white);
    font-size: 16px;
    line-height: 24px;
    padding: 15px 15px !important;
}

.cart-table-container thead tr th:nth-child(1) {
    width: 40%;
}

.items-listing .table thead tr th {
    background-color: var(--theme-primary-color);
    white-space: nowrap;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 15px !important;
}

.items-listing .table .product-checkout {
    display: flex;
    width: 400px;
    align-items: center;
}

.cart-table-container .product-image img {
    max-width: 50px;
}

.product-checkout .product-content .product_title {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 4px;
}

.items-listing .table .product-checkout .product-image-container {
    margin-right: 10px;
    margin-bottom: 0;
}

.product-checkout .product-content .product_title a {
    color: var(--theme-primary-color);
}

.cart-table-container tbody tr td {
    color: var(--black);
    font-size: 16px;
    line-height: 24px;
    vertical-align: middle;
    font-weight: 400;
    padding: 10px;
}


/* my-order-end */


/* order-issue-start */

.issue_inner {
    width: 100%;
    display: inline-block;
    background-color: #f4f4f4;
    padding: 20px;
}

.notification_head.ticket_list {
    width: 100%;
    display: inline-block;
    border-bottom: 1px solid var(--theme-primary-color);
    padding-bottom: 20px;
}

.notification_head h3 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
}

.ticket_list h3 {
    width: 50%;
    display: inline-block;
}

.ticket_list .add_ticket {
    width: 50%;
    display: inline-block;
    text-align: right;
}

.ticket_list .add_ticket a {
    padding: 7px 20px;
    border-radius: 8px;
}

.inside_ticket {
    width: 100%;
    display: inline-block;
}

.order-issue .notification_head {
    padding: 10px 0 0 0;
}

.inside_ticket .notification_head span {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.continue_btns {
    padding: 10px 14px;
    border-radius: 3px;
}

.ticket_listing:not(:last-child) {
    border-bottom: 1px solid rgb(206, 206, 206);
    padding-bottom: 20px;
}

.ticket_listing {
    width: 100%;
    display: block;
    /* padding: 20px 0px 20px 0px; */
    padding-top: 20px;
}

.ticket_id,
.issue_subject {
    width: 50%;
    display: inline-block;
}

.ticket_listing p {
    font-size: 16px;
    color: #1f1f1f;
    padding-bottom: 10px;
}

.ticket_id span {
    position: relative;
}

.ticket_id span .id_no {
    font-weight: bold;
    color: #1f1f1f;
}

.pending_issue,
.issue_date {
    width: 50%;
    display: inline-block;
    text-align: right;
}

.pending_issue span {
    padding: 6px 15px;
    background-color: var(--theme-primary-color);
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    display: inline-block;
}

.ticket_id,
.issue_subject {
    width: 50%;
    display: inline-block;
}

.ticket_listing p {
    font-size: 16px;
    color: #1f1f1f;
    padding-bottom: 10px;
}

.issue_subject span,
.details_issue_subject span {
    color: var(--theme-primary-color);
}

.emptycart-icon {
    text-align: center;
    display: inline-block;
    width: 100%;
}


/* order-issue-end */


/* my-wallet-start */

.inner_loyalty_point {
    width: 100%;
    display: inline-block;
    padding: 20px;
    background-color: #f4f4f4;
}

.not_div {
    width: 100%;
    display: inherit;
    padding-top: 10px;
    padding-bottom: 10px;
}

.top_wallet_div {
    width: 100%;
    display: inherit;
}

.wallet_div {
    padding: 40px 20px;
    margin-top: 20px;
    border-radius: 20px;
    box-shadow: 0px 12px 27px 7px #e6e6e6;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.wallet_logo {
    width: 26%;
    text-align: right;
    display: table-cell;
    vertical-align: middle;
}

.wallet_logo img {
    float: right;
}

.wallet_details,
.lp {
    width: 50%;
    display: table-cell;
    padding-top: 20px;
    padding-left: 10px;
    margin-top: 20px;
    color: #000;
}

.wallet_details>p {
    font-size: 16px;
    font-weight: 500;
    color: #222529;
    margin-bottom: 5px;
}

.not_div h4 {
    font-size: 24px;
    font-weight: 400;
}

.button_add_pref {
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 8px;
    font-size: 25px;
    font-weight: 700;
    height: 41px;
    padding-right: 10px;
    padding-left: 10px;
    /* float        : right; */
    border: 1px solid var(--theme-primary-color);
    transition: box-shadow ease-in-out 0.35s;
    background-color: var(--theme-primary-color);
}

.wallet_btn {
    position: absolute;
    top: 7px;
    right: 15px;
}

.bottom_inside {
    width: 100%;
    display: inline-block;
    padding: 15px;
    background-color: #fff;
    border: 1px solid rgb(216, 216, 216);
    margin-bottom: 10px;
}

.wallet_head h4 {
    font-size: 16px;
    color: #fff;
    line-height: 20px;
}

.trans_logo_container {
    position: absolute;
    width: 35px;
    top: 2px;
}


.refund p {
    font-size: 16px;
    color: rgb(58, 58, 58);
    font-weight: 700;
}

.badge-warning {
    color: #212529;
    background-color: #ffc107;
}

.refund_amt {
    width: 150px;
    text-align: right;
    margin-left: auto;
}

.refund_amt p {
    font-size: 20px;
    color: rgb(58, 58, 58);
    font-weight: 500;
}

.refund_date {
    padding-left: 45px;
}

.refund_date p {
    font-size: 14px;
    color: rgb(58, 58, 58);
}

.addmany {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: rgb(0 0 0/.7);
    z-index: 11111111;
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
    overflow-y: auto;
}

.addmany.open {
    opacity: 1;
    visibility: visible;
    transition: all 0.5s ease;
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
}

.addoutside {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    cursor: pointer;
}

.addmany .addmany-inner {
    background: #fff;
    position: relative;
    z-index: 2;
    max-width: 360px;

}

.close-icon {
    text-align: right;
    padding: 10px 20px 0 0px;
    position: absolute;
    right: 0;
    z-index: 5;
}

.close-icon i.fas {
    font-size: 27px;
    color: #000;
    cursor: pointer;
}

.addmany .user-icon img {
    height: 230px;
    margin: 0 auto;
    display: block;
}

.addmany-box {
    text-align: center;
    padding: 5px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.addmany-test {
    text-align: center;
}

.addmany-test span {
    font-size: 18px;
    text-transform: capitalize;
}

.addmany-box span {
    font-size: 44px;
    font-weight: 500;
    color: var(--theme-primary-color);
}

.addmany-box input[type="test"] {
    line-height: 40px;
    margin: 0 0 -4px 10px;
    border: none;
    font-size: 30px;
    font-weight: 600;
    max-width: 120px;
}

.addmany-box .subment-button a {
    background: var(--theme-primary-color);
    color: var(--white);
    font-size: 19px;
    min-width: 200px;
    display: inline-block;
    line-height: 41px;
    margin: 10px 0 20px 0;
    transition: all .3s;
}

.addmany-box .subment-button a:hover {
    background-color: var(--black);
}

.addmany-box .subment-button {
    flex: 0 0 100%;
}

.loyalty_div {
    padding: 40px 20px;
    margin-top: 20px;
    border-radius: 20px;
    box-shadow: 0px 12px 27px 7px #e6e6e6;
    position: relative;
    background-color: #ffcfc3;
}

.wallet_logo {
    width: 26%;
    text-align: right;
    display: table-cell;
    vertical-align: middle;
}

.wallet_logo img {
    float: right;
}

.wallet_details,
.lp {
    width: 50%;
    display: table-cell;
    padding-top: 20px;
    padding-left: 10px;
    margin-top: 20px;
    color: #000;
}

.lp p {
    font-size: 20px;
    color: #ee3f33;
    margin-bottom: 0px;
    line-height: 1.4;
}

.lp span {
    color: #000;
}

.lp h4 {
    margin-top: 0px;
}

.wallet_details a,
.lp a {
    font-size: 12px;
    color: var(--theme-primary-color);
    text-decoration: underline;
}

.lp_div_2 {
    background-color: #fff;
    padding: 15px 15px;
    border-radius: 3px;
}

.not_div.lp_div_2 h4 {
    margin-top: 0px;
    margin-bottom: 10px;
}

.offer_div {
    padding: 15px 10px;
    border: 1px solid #fff;
    border-radius: 8px;
    box-shadow: 1px 1px 12px 1px #e7e7e7;
    background-color: #fffbfb;
    margin: 0px 0px 20px 0px;
    display: flex;
    align-items: center;
}

.paddtext:last-child .offer_div {
    margin-bottom: 0;
}

.offer_details {
    width: 75%;
    display: flex;
    align-items: center;
}

.offer_para {
    margin-left: 3px;
}

.offer_para p {
    font-size: 15px;
}

.offer_link {
    width: 24%;
    display: inline-block;
    text-align: right;
}

.offer_link a {
    padding: 7px 15px;
    background-color: var(--theme-primary-color);
    color: #fff !important;
    border-radius: 3px;
    font-size: 15px;
    transition: box-shadow 0.35s ease-in-out;
}

.offer_link a:hover {
    background-color: var(--dark);
}

.offer_logo {
    width: 5%;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 5px;
}


/* my-wallet-end */


/* refer-friend-start */

.inside_notifications img {
    display: block;
    margin: 0px auto;
}

.note_area {
    text-align: center;
    width: 50%;
    margin: 30px auto;
}

.left_noti {
    width: 50%;
    display: inline-block;
    position: relative;
}

.inside_notifications p {
    font-size: 20px;
    text-align: center;
}

.inside_notifications p span {
    color: var(--theme-primary-color)
}

.left_noti h4 {
    position: absolute;
    top: 6px;
    text-align: center;
    left: 0;
    right: 0px;
    font-size: 20px;
    cursor: pointer;
}

.inside_ticket {
    width: 100%;
    display: inline-block;
}

.inside_ticket .product-single-share {
    width: 100%;
}

.inside_ticket .social-icons {
    width: 100%;
    text-align: center;
}

.social-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    color: #fff;
    background-color: var(--theme-primary-color);
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    text-decoration: none;
    opacity: 1;
}

.react-share__ShareButton {
    margin: 3px 2px;
}

.react-share__ShareButton svg {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    border: 2px solid #e7e7e7;
    text-decoration: none;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    line-height: 28px;
}

.react-share__ShareButton svg rect {
    fill: #fff;
}

.react-share__ShareButton svg path {
    fill: #000;
}

.react-share__ShareButton svg:hover {
    border-color: #25D366;
}

.react-share__ShareButton svg:hover rect {
    fill: #25D366;
}

.react-share__ShareButton svg:hover path {
    fill: #fff;
}


/* refer-friend-end */


/* Notifications-start */

.notifications_inside {
    width: 100%;
    display: inline-block;
    background-color: #eee;
    padding: 30px;
    min-height: 598px;
    overflow-y: auto;
}

.notifications_list ul li {
    border-bottom: 1px solid #929292;
    padding: 0 0 5px 0px;
    margin-bottom: 5px;
    position: relative;
    color: #000;
    font-size: 16px;
    line-height: 30px;
    padding-right: 0;
    display: flex;
    align-items: center;
}

.notifications_list ul li span {
    display: inline-block;
    text-align: right;
    position: relative;
    right: 0;
    top: 0;
    color: #000;
    font-size: 14px;
    margin: 0 15px 0 auto;
}

.notifications_list .btn {
    padding: 6px 15px;
    border-radius: 5px;
    min-width: 50px;
}


/* Notifications-end */


/* my-cart-start */

.whole_cart_left {
    width: 100%;
    display: inline-block;
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 0px;
}

.crt_lrt {
    width: 100%;
    display: inline-block;
    padding: 5px 0px;
}

.left_txt_crt {
    width: 80%;
    float: left;
}

.left_txt_crt h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}

.right_txt_crt {
    width: 20%;
    float: left;
}

.right_txt_crt h4 {
    text-align: right;
    font-size: 16px;
    font-weight: 600;
}

.inside_crt {
    width: 100%;
    display: flex;
    padding: 0 0 15px 0px;
    flex-wrap: wrap;
}

.inside_crt .media_cart {
    width: 80px;
}

.inside_crt .list_iten_cart {
    width: calc(100% - 230px);
    padding: 0 15px;
}

.cart_content .price-list {
    margin-bottom: 5px;
}

.product-price {
    color: #222529;
    font-size: 18px;
    line-height: .8;
    margin-right: 8px;
}

.cart_content .product-filter {
    color: #000000;
    display: flex;
    margin-top: 8px;
    font-size: 14px;
}

.cart_content .product-filter span {
    margin-left: 5px;
    font-weight: 700;
}

.inside_crt+.inside_crt {
    border-top: 2px solid #cec8c8;
    padding-top: 15px;
}

.inside_crt .increase_product.circle_mp {
    width: 150px;
    display: flex;
    align-items: center;
}

.whole_cart_left .def-number-input.number-input {
    border: none;
    display: flex;
    align-items: center;
}

.inside_crt .increase_product.circle_mp .cart-deleteicon {
    margin-left: auto;
    height: 32px;
    width: 32px;
    border: 2px solid var(--theme-primary-color);
    border-radius: 30px;
    text-align: center;
    line-height: 28px;
    color: var(--theme-primary-color);
    cursor: pointer;
}

.process_cart {
    width: 100%;
    display: inline-block;
    background-color: #f4f4f4;
    padding: 20px 10px 20px 10px;
    margin-bottom: 10px;
}

.process_cart ul {
    display: flex;
    justify-content: space-around;
}

.process_cart ul li {
    position: relative;
}

.process_cart ul li a {
    color: #000;
    font-size: 16px;
    line-height: 24px;
}

.process_cart ul li.active a i {
    color: var(--theme-primary-color);
}

.process_cart ul li:nth-child(1)::after,
.process_cart ul li:nth-child(2)::after {
    content: " ";
    display: block;
    position: absolute;
    width: 32px;
    height: 2px;
    background-color: #cec8c8;
    right: -38px;
    top: 12px;
}

.right_cart_inside {
    width: 100%;
    display: inline-block;
    padding: 15px;
    background-color: #f4f4f4;
}

.right_cart_inside h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    padding: 0 0 15px 0px;
}

.right_cart_inside ul {
    margin: 0;
}

.right_cart_inside ul li {
    padding: 12px 0px;
    border-bottom: 1px solid #b4a09a;
    font-size: 15px;
    position: relative;
}

.right_cart_inside ul li:last-child {
    border-bottom: none;
}

.right_cart_inside ul li .delivery-charges {
    font-weight: 400;
    font-size: 13px;
    padding-right: 55px;
    margin-top: 5px;
}

.right_cart_inside ul li span {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.right_cart_inside ul li.border-bottom {
    border-top: 3px solid var(--theme-primary-color);
    border-bottom: 1px solid #b4a09a !important;
}

.right_cart_inside ul li label {
    margin: 0;
    color: #222529;
    font-weight: 700;
}

.crat_bt a {
    float: right;
    display: block;
    padding: 8px 15px;
    background-color: var(--theme-primary-color);
    color: #fff;
    border-radius: 3px;
    font-weight: 600;
    margin-top: 15px;
}

.whole_cart_left .def-number-input.number-input input.quantity {
    background: transparent;
    border: none;
    font-size: 16px;
    width: 30px;
    max-width: unset;
    padding-top: 0;
    padding-bottom: 0;
    height: 32px;
}

.left_txt_crt h4 span {
    font-size: 14px;
    color: #999;
    font-weight: 400;
    margin-left: 8px;
}


/* my-cart-end */


/* checkout-start */

.allCheckout {
    width: 100%;
    display: table;
    margin: 0px auto;
}

.checkout_left {
    width: 100%;
    float: none;
    /* margin-right: 20px; */
    border: 1px solid #d9d9d9;
}

.couter_list {
    width: 100%;
    display: inline-block;
    position: relative;
}

.couter_list.active::before {
    background-color: #00a100;
    border: 1px solid #00a100;
    color: #ffffff;
    z-index: 7;
    content: "✓" !important;
}

.couter_list.active h4 {
    background: #fff;
    border-top: 1px solid #d9d9d9;
    color: #222529;
    font-weight: 500;
    padding: 15px 25px 0px 60px;
    position: relative;
}

.verified-datetime {
    padding-top: 10px;
}

.address_checkout {
    width: 100%;
    display: inline-block;
    padding: 0px 25px 15px 60px;
}

.couter_list::before {
    counter-increment: section;
    content: counter(section) !important;
    color: var(--theme-primary-color);
    font-size: 12px;
    border: 1px solid var(--theme-primary-color);
    border-radius: 50%;
    position: absolute;
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 24px;
    z-index: 99;
    top: 17px;
    left: 15px;
    z-index: 7;
}

.couter_list h4 {
    background: #fbfbfb;
    border-top: 1px solid #d9d9d9;
    color: #666;
    font-size: 16px;
    font-weight: 300;
    padding: 15px 25px 15px 60px;
    position: relative;
    text-align: left;
}

.block_edit_add {
    width: 100%;
    margin-left: 0;
    position: relative;
    padding: 0 25px 15px 60px;
}

.editable_check_add p {
    color: var(--theme-primary-color);
    font-size: 16px;
    line-height: 24px;
}

.editable_check_add p a {
    color: var(--theme-primary-color);
}

.check_add_box {
    width: 100%;
    display: inline-block;
    border: 1px solid #eee;
    margin-top: 20px;
    position: relative;
    padding: 7px 20px 20px;
    border-radius: 5px;
}

.check_add_box h3 {
    font-size: 14px;
    padding: 10px 0px 5px 0px;
    font-weight: 600;
}

.icon_edit {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 15px;
    top: 15px;
}

.check_add_box h5 {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 5px 0;
}

.check_deliver {
    border: 1px solid var(--theme-primary-color);
    background-color: var(--theme-primary-color);
    color: #fff;
    border-radius: 3px;
    padding: 5px 10px;
    margin-top: 10px;
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
}

.couter_list {
    width: 100%;
    display: inline-block;
    position: relative;
}

.couter_list:first-child h4 {
    border-top: none;
}

.payment_tab {
    width: 100%;
    display: inline-block;
    padding: 25px 50px 25px 70px;
}

.totalCalcualte {
    width: 100%;
    display: inline-block;
    /* border       : 1px solid #eee; */
    padding: 0;
    border-radius: 3px;
    padding: 0 15px 15px 60px;
}

.top_left_t p {
    font-size: 15px;
    line-height: 22px;
    padding-bottom: 15px;
}

.top_left_t p span {
    text-align: right;
    float: right;
}

.bottom_both {
    border-top: 1px dotted #dbdbdb;
    margin-top: 10px;
    margin: 0;
    padding-top: 20px;
    font-weight: 700;
}

.bottom_both p {
    color: #222529;
}

.bottom_both p span {
    color: #222529;
    text-align: right;
    float: right;
}

.bottom_both:last-child {
    border: 0;
}

.referal_code {
    width: 100%;
    display: inline-block;
    padding: 0px 15px 0px 60px;
}

.referal_code input {
    border: 1px dashed #bcbcbc;
    padding: 10px;
    background-color: #eee !important;
    border-radius: 3px;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    width: 100%;
    height: 46px;
}

.referal_code .input-group .input-group-text {
    font-size: 14px;
    padding: 0 15px;
    cursor: pointer;
    background-color: #bd3042;
    border: 1px solid #bd3042;
    color: #fff;
}

.payment_tab {
    width: 100%;
    display: inline-block;
    padding: 25px 50px 25px 60px;
}

.payment_tab ul {
    width: 100%;
    position: relative;
    display: flex;
    float: left;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.payment_tab .nav-tabs {
    border-bottom: 0px solid #e7e7e7;
}

.payment_tab .nav-item {
    white-space: normal;
    vertical-align: top;
    position: relative;
    display: inline-block;
}

.payment_tab .nav-item {
    border-left: 1px solid #ccc;
    margin: 0px;
    border-top: 1px solid #ccc;
}

.payment_tab .nav-tabs .nav-item a {
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #333;
    text-transform: uppercase;
    text-align: center;
    padding: 14px 12px 12px 12px;
    position: relative;
}

.payment_tab .nav-tabs .nav-item.show .nav-link,
.payment_tab .nav-tabs .nav-item .nav-link.active {
    border-color: var(--theme-primary-color);
    color: #fff;
    background-color: var(--theme-primary-color);
    border-radius: 0px;
}

.payment_tab .nav-tabs .nav-item:last-child {
    border-right: 1px solid #ccc;
}

.payment_tab .tab-content {
    border: 1px solid #ccc;
    width: 100%;
    display: inline-block;
}

.payment-wallets {
    height: auto;
}

.indivisual_pay {
    width: 100%;
    display: inline-block;
    padding: 20px 0px;
    border-bottom: 1px solid #eee;
}

.indivisual_pay:last-child {
    border-bottom: none;
}

.wallet-opt.card-selection {
    display: inline-block;
    margin-bottom: 0;
    cursor: pointer;
}

.wallet-opt.card-selection input {
    width: 20px;
    float: left;
    margin-top: 0;
    margin-right: 20px;
}

.wallet-type {
    margin-right: 20px;
}

.card-selection__details {
    display: flex;
    padding-left: 35px;
}

.wallet_name {
    display: block;
    font-size: 15px;
    font-weight: 400;
    color: var(--theme-primary-color);
    line-height: 22px;
}

.details_payemt {
    font-size: 13px;
    color: rgb(51, 51, 51);
    line-height: 21px;
    margin-top: 0;
    display: inline-block;
    font-weight: 600;
}

.make_payment_new {
    width: 95%;
    padding: 10px;
    font-size: 14px;
    line-height: 21px;
    color: #fff;
    border-radius: 3px;
    font-weight: 500;
    background-color: #c4c4c4;
    margin: 0 auto 20px;
    display: block;
}

.make_payment_new:hover {
    color: #fff;
}

.make_payment_new.red {
    background-color: var(--theme-primary-color);
}

.other_method {
    width: 100%;
    display: block;
    padding: 20px;
    margin: 0px auto;
}

.other_method label {
    margin-right: 16px;
    font-size: 15px;
    font-weight: 400;
}

.other_method label input {
    margin-right: 8px;
}

.input-row .single-input-item {
    display: flex;
    flex-direction: column;
    padding: 0px 20px 20px 20px;
    position: relative;
}

.input-row .single-input-item label {
    font-size: 14px;
    color: #222;
    display: block;
    margin-bottom: 10px;
    position: relative;
    font-weight: normal;
}

.input-row .single-input-item input {
    box-shadow: none !important;
    border-radius: 0 !important;
    height: 50px;
    font-size: 14px;
    border: 1px solid #e7e7e7;
    display: block;
    outline: 0;
    padding: 15px 20px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    width: 100%;
}

.continue_btns {
    background: var(--theme-primary-color);
    border-radius: 3px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: inherit;
    font-size: 14px;
    outline: none;
    padding: 10px 20px;
    text-align: center;
    font-weight: 500;
}

.continue_btns:hover {
    border-color: var(--black);
    background-color: var(--black);
}

.editaddressModal .allImgNo {
    padding: 0px !important;
    margin-bottom: 10px;
}

.editaddressModal .allImgNo img {
    height: unset !important;
    max-height: 160px !important;
    object-fit: contain;
    border: none
}

.c-done {
    display: none !important;
}

.check_deliver:hover,
.procee_payment_check a:hover,
.card_btn:hover {
    background-color: #eee;
    color: var(--theme-primary-color);
    border-color: #eee;
}

.verified-address {
    margin-top: 15px;
}

.change_address .change-btn {
    position: absolute;
    right: 15px;
    top: 13px;
    border: 1px solid var(--theme-primary-color);
    background-color: var(--theme-primary-color);
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
}

.address_checkout {
    width: 100%;
    display: inline-block;
    padding: 0px 25px 15px 60px;
}

.address_checkout li {
    list-style: none;
}

h4.login_modal_title i.fas.fa-sms {
    background-color: #bd3042 !important;
    color: #fff;
    font-size: 38px;
    line-height: 38px;
    padding: 10px;
    border-radius: 100px;
    width: 58px;
    margin: 0 10px 0 0;
}


/* checkout-end */


/* after-login-end */


/* login-popup-start */


/* login-popup-end */

.styles_modal__gNwvD {
    width: unset;
    max-width: unset !important;
    padding: 12px;
}

.login_modal_title,
.register_modal_title {
    color: #333;
    font-size: 21px;
    line-height: normal;
    margin: 0;
    text-align: center;
    font-weight: 400;
    border-bottom: 1px solid #eee;
    padding: 28px 0;
}

h4.login_modal_title {
    display: flex;
    align-items: center;
    justify-content: center;
}

h4.login_modal_title i.fab.fa-whatsapp {
    background-color: rgb(77, 194, 71) !important;
    color: #fff;
    font-size: 24px;
    line-height: 42px;
    border-radius: 100px;
    width: 44px;
    margin: 0 10px 0 0;
    height: 44px;
}

.login_modal_number_box,
.register_modal_number_box {
    margin: 0px auto;
    padding: 10px 0px 20px 0px;
    width: 100%;
    display: inline-block;
}

.login_modal_number_box p,
.register_modal_number_box p {
    display: block;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    text-align: center;
    margin-bottom: 10px;
}

.login_modal_phone,
.register_modal_phone {
    width: 300px;
    display: block;
    margin: 0px auto;
    position: relative;
}

.PhoneInputCountry {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.login_modal_phone input,
.register_modal_phone input {
    display: block;
    margin: 0px auto;
    height: 45px;
    width: 100%;
    font-size: 14px;
    padding-left: 78px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    color: #333;
    cursor: text;
}

.login_modal_phone.number-hide input {
    padding-left: 50px !important;
}

.common_login_sign_up_btn {
    max-width: 300px;
    width: 100%;
    margin: 0px auto;
    display: block;
    height: 42px;
    font-size: 16px;
    line-height: 24px;
    padding: 0px;
    margin-top: 15px;
    border-radius: 3px;
    font-weight: 400;
    color: #fff;
    border: 0;
    box-shadow: none;
}

.login_otp_resend,
.register_otp_resend {
    color: #bd3042 !important;
    cursor: pointer !important;
    display: block !important;
    font-size: 14px !important;
    margin-top: 12px !important;
    text-align: center !important;
    width: 100% !important;
}

p.register_otp_resend.hide {
    color: #ccc !important;
    pointer-events: none !important;
}

p.register_otp_resend.active span {
    display: none;
}

p.register_otp_resend.active {
    display: block;
}

.styles_closeButton__20ID4 {
    top: 5px;
    right: 5px;
}

.login_modal_next_btn,
.register_modal_next_btn {
    background-color: #ccc;
}

.login_modal_next_btn_click,
.register_modal_next_btn_click,
.common_login_sign_up_btn_click {
    background: var(--theme-primary-color);
}

.login_modal_number_box p,
.register_modal_number_box p {
    display: block;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    text-align: center;
    margin-bottom: 10px;
}

.login_modal_otp_box,
.register_modal_otp_box {
    width: fit-content !important;
    display: block;
    margin: 0px auto;
}

.login_modal_otp_box input,
.register_modal_otp_box input {
    width: 42px !important;
    height: 48px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    color: #333;
    cursor: text;
    font-size: 14px;
    outline: 0;
    padding: 12px 10px;
    margin: 0 10px;
    font-size: 16px;
    text-align: center;
}

.header-search.show .search-toggle:after {
    content: '';
    position: absolute;
    right: calc(50% - 10px);
    bottom: -10px;
    border: 10px solid transparent;
    border-bottom-color: #BD3042;
}

.nodatafound {
    text-align: center;
    padding: 40px 0 50px 0;
}

.nodatafound span {
    font-size: 25px;
    font-weight: 600;
    color: #000;
}

.nodatafound p {
    font-size: 18px;
}

.category-navs__current {
    text-align: center;
    font-size: 23px;
    color: #000;
    font-weight: 400;
    margin: 20px 0 20px 0;
}

.product-single-share .icon-whatsapp:before {
    font-size: 19px;
}

.product-single-share .social-mail {
    line-height: 25px;
}

.product-single-share .fa-heart {
    display: flex;
}

.icon-heart.add-wishlist:before,
.product-single-share .fa-heart::before {
    width: 30px;
    height: 30px;
    border: solid 1px #e7e7e7;
    border-radius: 50%;
    line-height: 30px;
    font-size: 18px;
    transition: all 0.4s ease-in-out;
    text-align: center;
}

.product-single-share .fa-heart::before {
    font-size: 14px;
}

.icon-heart.add-wishlist:hover:before {
    border-color: var(--theme-primary-color);
    background-color: var(--theme-primary-color);
    color: var(--white);
}

.product-single-share .fa-heart::before {
    border-color: var(--theme-primary-color);
    background-color: var(--theme-primary-color);
    color: var(--white);
}

.product-single-share .fa-heart span {
    line-height: 28px;
}


/* error-page-start */

.success-text {
    text-align: center;
}

.success-text p.small-title {
    font-size: 26px;
    font-weight: 600;
    color: #d84757;
    margin-bottom: 15px;
    text-transform: capitalize;
}

.success-text h2 {
    font-size: 40px;
    font-weight: 700;
    text-transform: capitalize;
    color: #d84757;
    margin: 0 0 10px 0;
}

.success-text .content ul {
    padding: 15px;
}

.success-text .content li {
    margin-bottom: 7px;
    font-size: 15px;
    line-height: 1.6;
    font-weight: 400;
    font-family: Poppins, sans-serif;
    color: #666;
}

.success-text .content li a {
    color: var(--theme-primary-color);
    font-weight: 500;
}

.success-text .content li a:hover {
    color: var(--theme-secondary-color);
}

.toolbox,
.toolbox-left,
.toolbox-right,
.toolbox-item {
    margin-bottom: 10px;
}

/* error-page-end */

/* tooltip-start */
.ticket_id .tooltip {
    position: absolute;
    top: -60px;
    left: -20px;
    width: 120px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
}

.ticket_id .tooltip .arrow {
    left: 45%;
    transform: translateX(-50%);
}

.bs-tooltip-top .arrow:before,
.bs-tooltip-auto[x-placement^='top'] .arrow:before {
    margin-left: -5px;
    border-width: 10px 10px 0;
    border-top-color: #ddd;
}

.tooltip-inner {
    max-width: 270px;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 0.1rem;
    background-color: #f4f4f4;
    color: #bd3042;
    text-align: left;
}

.ticket_id span a:hover+.tooltip {
    opacity: 1;
    visibility: visible;
}

/* tooltip-end */

.inside_ticket .ant-input .ant-picker input {
    border: none;
}

.inside_ticket .ant-input .ant-picker {
    padding: 5px 0 0 0;
    border: none !important;
}

.personal_detail_form input[placeholder] {
    font-size: 15px;
    color: #929292;
}

.inside_ticket input.ant-input {
    line-height: 34px;
    border: 1px solid #dadada;
}

.input_container {
    margin-top: 20px;
    margin-bottom: 0px;
    position: relative;
}

.ticket_div {
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
}

.content_inside {
    width: 100%;
    display: inline-block;
    padding: 25px 25px 25px 70px;
}

.login_btn_check {
    width: 300px;
    position: relative;
    float: left;
    margin-top: 15px;
    margin-right: 20px;
}

.login_btn_check label {
    font-weight: 400;
    font-size: 14px;
}

.PhoneInput {
    display: flex;
    align-items: center;
}

.next_btn_check {
    background-color: #ccc;
    max-width: 100px;
    width: 100%;
    display: block;
    height: 44px;
    font-size: 16px;
    line-height: 24px;
    padding: 0px;
    border-radius: 3px;
    font-weight: 400;
    color: #fff;
    border: 1px solid #ccc;
    box-shadow: none;
    cursor: pointer;
    margin-top: 45px;
    margin-left: 20px;
}

button.next_btn_check.active {
    background: #bd3042;
}

body .verified-number,
body .otp_verification {
    width: auto;
    width: 100%;
    display: inline-block;
    padding: 25px 25px 25px 70px;
}

p.whatsapp-verification-label,
.checkout_left .otp_verification p {
    font-size: 15px;
    line-height: 1.6;
    color: #777777;
    text-align: left;
    text-transform: capitalize;
}

.checkout_left .otp_verification p {
    margin-bottom: 15px;
}

.otp_verification label {
    font-weight: 400;
    font-size: 14px;
}

.otp_verification label img.edit-img {
    width: 13px;
    padding-left: 2px;
    margin-left: 5px;
    display: inline-block;
    filter: invert(30%) sepia(76%) saturate(2726%) hue-rotate(333deg) brightness(76%) contrast(91%);
}

.otp_verification label a {
    padding: 0 5px;
    color: var(--theme-primary-color);
}

.allCheckout .register_modal_number_box {
    padding: 0;
    width: unset !important;
    margin: 0px !important;
    display: inline-block;
}

.allCheckout .register_modal_number_box .login_otp_input input {
    width: 42px !important;
    height: 48px;
    float: left;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    color: #333;
    cursor: text;
    font-size: 14px;
    outline: 0;
    padding: 12px 10px;
    font-size: 16px;
    text-align: center;
}

.dash-icon {
    margin: 0 10px;
}

.allCheckout .register_modal_number_box+button.register_modal_next_btn.common_login_sign_up_btn {
    margin-top: 0px;
    margin-left: 15px;
    height: 48px;
    max-width: 100px;
}

.allCheckout p.register_otp_resend {
    text-align: left !important;
}

.address_type label {
    margin-right: 15px;
    font-size: 15px;
}

/* terms-and-condition-start */
.listing-head h2 {
    color: #d84757;
    margin-bottom: 35px;
    font-weight: 700;
    font-size: 36px;
}

.listing-content p {
    margin-bottom: 15px;
    font-size: 15px;
    line-height: 1.6;
    font-weight: 400;
    color: #666;
    font-family: 'Poppins', sans-serif;
}

.listing-content div ul {
    list-style: none;
    padding-left: 30px;
    margin-bottom: 30px;
}

.listing-content div ul li {
    margin-bottom: 15px;
    font-size: 15px;
    line-height: 1.6;
    font-weight: 400;
    color: #666;
}

.listing-content h4 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #d84757;
}

.dropdown-arrow .badge-circle {
    top: -10px;
    width: 18px;
    height: 18px;
    line-height: 18px;
}

/* terms-and-condition-end */

/* hemansi-css-start */

/* footer css ========================================================================= */

.footer-wrapper {
    background-color: var(--theme-secondary-color);
    margin-top: 50px;
}


/* get in touch tag css  */

.getintouch-tag {
    position: absolute;
    top: -17px;
    left: 0;
    min-width: 120px;
    padding: 10px;
    font-size: 18px;
    line-height: 22px;
}

.getintouch-tag::before {
    position: absolute;
    content: '';
    top: 0;
    left: -15px;
    display: block;
    border-top: 17px solid transparent;
    border-right: 15px solid var(--theme-primary-color);
}


/* footer middle css  */

.footer-middle {
    padding-top: 60px;
}

.footer-middle .widget {
    margin-bottom: 30px;
}

.footer-middle .widget-title {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 17px;
}


/* contact info css  */

.footer-middle ul.contact-info li {
    line-height: 1;
    margin-bottom: 20px;
    display: block;
}

.footer-middle ul.contact-info li span,
.footer-middle ul.contact-info li span a {
    font-size: 12px;
    font-weight: 400;
    color: #979797;
    line-height: 1.4;
}

.footer-middle ul.contact-info li span a:hover {
    color: var(--theme-primary-color);
}

.footer-middle ul.contact-info li p.contact-info-label {
    font-size: 13px;
    margin-bottom: 2px;
    text-align: left;
}


/* social icons css  */

.footer-middle .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 27px;
    border-radius: 50%;
    border: 2px solid #979797;
    display: inline-block;
    color: #979797;
    text-align: center;
}

.footer-middle .social-icons a:hover {
    border-color: var(--theme-primary-color);
    color: var(--white);
}

.footer-middle .social-icons a+a {
    margin-left: 10px;
}

.footer-middle .social-icons a i {
    font-size: 15px;
}


/* widget-newsletter css  */

.widget-newsletter p {
    font-size: 14px;
    line-height: 20px;
}

.widget-newsletter .form-control,
.widget-newsletter .btn {
    padding: 8px 18px;
    border-radius: 30px;
    font-size: 14px;
}

.widget-newsletter .form-control {
    height: 44px;
    color: var(--theme-primary-color);
    box-shadow: none;
    border: 1px solid transparent;
}

.widget-newsletter .btn {
    min-width: 120px;
    font-weight: 500;
}

.widget-newsletter .btn:hover {
    background-color: var(--black);
}


/* links ul css */

.links-ul li {
    display: block;
    margin-bottom: 5px;
}

.links-ul li a {
    font-size: 13px;
    color: #979797;
}

.links-ul li a:hover {
    color: var(--white);
}


/* download app icon css  */

ul.download-app li {
    margin-right: 5px;
}

ul.download-app li img {
    height: 33px;
}


/* copywrite bottom footer  */

.footer-bottom {
    border-top: 1px solid #313438;
    padding: 15px 0;
}

.footer-bottom p {
    font-size: 13px;
}


/* scroll to top btn css ========================================================================= */

#scroll-top {
    position: fixed;
    height: 40px;
    width: 40px;
    line-height: 40px;
    right: 15px;
    bottom: 55px;
    z-index: 9999;
    color: var(--white);
    background-color: var(--theme-primary-color);
    font-size: 16px;
    visibility: hidden;
    opacity: 1;
    border-radius: 0 0 0 0;
    transition: all .3s, margin-right 0s;
    transform: translateY(40px);
}

#scroll-top.fixed {
    visibility: visible;
}

#scroll-top:hover {
    background-color: var(--black);
}


/* footer bottom right btn fixed css =========================================================================  */

.footer-wrapper .footerbutton {
    position: fixed;
    bottom: 30px !important;
    right: auto !important;
    left: 30px;
    z-index: 155;
}

.footer-wrapper .fab-container {
    margin: 0;
}

.footer-wrapper .fab-container .icon-whatsapp:before {
    font-size: 32px;
}

/* comman icon area css  */

.footer-wrapper .contact-icon button {
    width: 50px !important;
    height: 50px !important;
    line-height: 50px;
    font-size: 24px;
    box-shadow: rgb(0 0 0 / 40%) 2px 2px 6px !important;
    transition: all 0.5s ease 0s !important;
    z-index: 200;
    visibility: hidden;
}

.footer-wrapper .contact-icon .message-icon {
    background-color: rgb(189, 48, 66) !important;
    visibility: visible !important;
    transition: all 0.5s ease 0s !important;
}

.footer-wrapper .contact-icon .message-icon:focus {
    transform: rotate(0);
    -webkit-transform: rotate(0);
}

.footer-wrapper .contact-icon .email-icon {
    background-color: rgb(132, 132, 132) !important;
}

.footer-wrapper .contact-icon .whatsapp-icon {
    background-color: rgb(77, 194, 71) !important;
}

.footer-wrapper .contact-icon:hover button {
    visibility: visible;
}


/* all icon for comman tooltip css  */

.footerbutton span.tooltip-txt {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    white-space: nowrap;
    padding: 4px 9px 6px;
    margin: auto 0px auto 14px;
    border-radius: 4px;
    border: 1px solid rgb(226, 226, 226);
    color: rgb(51, 51, 51);
    background: #ffffff;
    box-shadow: rgb(0 0 0 / 20%) 2px 2px 5px;
    z-index: 100;
    position: absolute;
    left: 45px;
    bottom: 10px;
    height: 27px;
    -webkit-transition: all 0.5s ease 0s !important;
    transition: all 0.5s ease 0s !important;
    display: none;
}

.footerbutton span.tooltip-txt:after {
    content: '';
    position: absolute;
    background: #ffffff;
    border-top: 1px solid rgb(226, 226, 226);
    border-left: 1px solid rgb(226, 226, 226);
    left: -5px;
    top: 50%;
    margin-top: -4px;
    width: 8px;
    height: 8px;
    z-index: 1;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}


/* conatct us icon tooltip message css  */

.footer-wrapper .footerbutton .contact-icon+span.tooltip-txt.show {
    left: 35px;
    bottom: 15px;
    display: block;
}

.footer-wrapper .footerbutton .contact-icon:hover+span.tooltip-txt.show {
    display: none;
    transition: all 0.5s ease 0s !important;
}


/* whatapp & mail hover time tooltip show css  */

.footer-wrapper .contact-icon button:hover .tooltip-txt {
    display: block;
    transition: all 0.5s ease 0s !important;
}


/*  conatctus "message-icon" hover time icon change and rotate css  */

.contact-icon:hover button.icon-chat.fab-rotate::before {
    transform: rotate(45deg) !important;
    -webkit-transform: rotate(45deg) !important;
    content: url("../assets/images/close-icon.svg");
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: 40px;
    background-repeat: no-repeat;
    max-height: 40px;
    transition: all 0.3s ease !important;
}


/* product-categorized-wrapper section css ========================================================================= */

.product-categorized-wrapper h4 {
    font-size: 16px;
    margin-bottom: 15px;
    color: var(--theme-secondary-color);
}

.product-categorized-wrapper .product-widget {
    margin-bottom: 15px;
}

.product-categorized-wrapper .product-widget figure {
    max-width: 85px;
    margin: 0 15px 0 0;
}

.product-categorized-wrapper .product-widget figure img {
    max-width: 85px;
    max-height: 85px;
    object-fit: cover;
    object-position: center;
}

.product-categorized-wrapper .product-details h2 a {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: var(--black);
}

.product-categorized-wrapper .product-details h2 a:hover {
    color: var(--theme-primary-color);
}


/* price title css  */

.product-categorized-wrapper .product-details .price-box {
    font-size: 15px;
    font-weight: 600;
}

.product-categorized-wrapper .product-details .price-box .product-price {
    font-size: 15px;
}


/* rating css  */

.ratings-container {
    margin-bottom: 0px !important;
}

.ant-rate {
    font-size: 12px !important;
}

.ant-rate-star:not(:last-child) {
    margin-right: 1px;
}


/* brand logo section css =========================================================================  */

.brands-slider-content {
    border-top: 1px solid var(--light-gray);
    border-bottom: 1px solid var(--light-gray);
    padding: 15px 0;
}


/* blog-slider css =========================================================================  */

.blog-section .blog-slider .post-media img {
    min-height: 200px;
    object-fit: cover;
}


/* comman blog box css  */

.blog .post .post-media {
    margin-bottom: 15px;
}

.blog .post .post-body .post-title {
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 15px;
    font-weight: 700;
    text-transform: capitalize;
}

.blog .post .post-body .post-title a {
    color: var(--theme-secondary-color);
}

.blog .post .post-body .post-title a:hover {
    color: var(--theme-primary-color);
    text-decoration: underline;
}

.blog .post .post-body .post-content p {
    font-size: 13px;
    line-height: 1.746;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 400;
}


/*blog page css =========================================================================  */

.blog-section {
    padding: 50px 0 0;
}

.blog-listing-wrapper article.post {
    box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 20%);
}

.blog-listing-wrapper .post .post-media img {
    width: 100%;
}

.blog-listing-wrapper .post-body {
    padding: 0 15px 10px 15px;
}


/* promo-banner  =========================================================================  */

.promo-banner .banner-content h2 {
    font-size: 30px;
    line-height: 1.15;

}

.promo-section .banner-content h2.bg-white-title {
    background-color: rgb(255, 255, 255, 0.5);
    padding: 6px;
    font-size: 25px;
}


/* banner-big-sale css  */

.banner-big-sale {
    padding: 15px 25px;
}

.banner-big-sale h2 {
    font-size: 20px;
    line-height: 1.2;
}

.banner-big-sale h2 b {
    z-index: 1;
}

.banner-big-sale h2 b::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--black);
    transform: rotate(-2deg);
    z-index: -1;
}

.banner-big-sale h2 b::after:hover {
    background-color: var(--theme-secondary-color);
}

.banner-big-sale h2 small {
    font-size: 14px;
    font-weight: 400;
    opacity: .7;
    margin-left: 8px;
    text-transform: capitalize;
}

.banner-big-sale .btn {
    padding: 15px 40px;
}

/* breadcum css =========================================================================  */

.breadcrumb-section {
    background-color: #f8f8f8;
    padding: 24px 0;
}

.breadcrumb-section h2 {
    font-size: 16px;
    line-height: 24px;
}


/* testimonial page css =========================================================================  */

.testimonial-wrapper ul li {
    margin-bottom: 15px;
}

.testimonial-box .manufacturing-video img.img-fluid {
    cursor: pointer;
    width: 100%;
}

.manufacturing-video .play-video {
    position: absolute;
    z-index: 1;
    top: 50%;
    text-align: center;
    width: 100%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.manufacturing-video .play-video img.img-fluid {
    width: auto;
}

.testimonial-box .testimonial-content {
    padding: 10px;
}

.testimonial-box .testimonial-content p {
    font-size: 14px;
    color: var(--gray);
    margin-bottom: auto;
}

.testimonial-content .bottom-txt h5,
.testimonial-content .bottom-txt h6 {
    font-size: 13px;
}


/* video modal css  */

.styles_modal__gNwvD {
    width: unset;
    max-width: unset !important;
    box-shadow: none;
}

.video-modal12 {
    width: 100%;
    min-width: 1140px;
    display: inline-block;
}

.video-responsive122 {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    width: 100%;
    display: inline-block;
}

.video-responsive122 iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    margin: 0px !important;
}

.video-modal12+button {
    background: #d84757;
    position: absolute;
    top: -20px;
    right: -20px;
    color: #fff;
    fill: #fff;
    border-radius: 100px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-modal12+button svg {
    margin: 0 auto;
}


/*coantact page css =========================================================================  */

.contact-page iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.contact-page .contact-right ul li .contact-icon {
    width: 130px;
}

.contact-page .contact-right ul li .contact-icon h6 {
    font-weight: 600;
}

.contact-page .contact-right ul li .media-body {
    position: relative;
    padding-left: 20px;
}

.contact-page .contact-right ul li .media-body::before {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
    background-color: #979797;
    content: "";
    left: 0;
}

.contact-page .contact-right ul li p.address-txt {
    font-size: 14px;
    color: #979797;
    line-height: normal;
    font-weight: 400;
}

.contact-page .contact-right ul li p.mb-0 {
    font-size: 14px;
    line-height: 30px;
    font-weight: 600;
}


/* contact-form css  */

.contact-page .contact-form .profile_btn {
    padding: 6px 15px;
}


/*store location page css =========================================================================  */

.store-location-listing .section-title h2 {
    font-size: 30px;
    color: var(--theme-primary-color);
}

.store-location-listing .state-box {
    padding: 15px;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
    font-family: 'Poppins', sans-serif;
}

.store-location-listing .state-box+.state-box {
    margin-top: 30px;
}

.state-box .state-img img {
    max-height: 210px;
}

.state-box .state-content .state-content-inner {
    padding-bottom: 15px;
}

.state-box .state-content .state-content-inner+.state-content-inner {
    padding-top: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.5)
}

.state-box .state-content .content-list li {
    display: flex;
    align-items: baseline;
}

.state-box .state-content .content-list li+li {
    margin-top: 5px;
}

.state-box .state-content .content-list li label {
    width: 120px;
    font-weight: 500;
}

.state-box .state-content .content-list li span {
    width: calc(100% - 120px);
    font-size: 15px;
}


/* aboutus page css =========================================================================  */
/* cms page title css  */
.cms-page-title h2 {
    font-size: 30px;
    font-weight: 700;
    text-transform: capitalize;
    color: var(--theme-primary-color);
}

/* cms section title icon & txt css  */
.cms-inner-wrapper .listing-content .listing-title {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.cms-inner-wrapper .listing-title .title-icone {
    display: inline-block;
    width: 60px;
    height: 60px;
    text-align: center;
    border-radius: 50%;
    background-color: #ffffff;
    padding: 10px;
    vertical-align: middle;
    margin-right: 10px;
    border: 1px solid #d84757;
}

.cms-inner-wrapper .listing-title.d-flex h4 {
    width: calc(100% - 60px);
}

/* comman title & text css  */
.cms-inner-wrapper .listing-content h4 {
    font-size: 24px;
    margin-bottom: 5px;
    color: var(--theme-primary-color);
    text-transform: capitalize;
}

/* ol li css  */
.cms-inner-wrapper ul li,
.cms-inner-wrapper ol li {
    font-weight: normal;
    list-style: disc outside;
    margin-left: 30px;
    text-transform: capitalize;
    font-size: 15px;
    line-height: 1.6;
    font-weight: 400;
    color: #666;
    font-family: 'Poppins', sans-serif;

}

.cms-inner-wrapper ul li:not(:last-child) {
    margin-bottom: 10px;
}

/* listing note css  */
.cms-inner-wrapper ul li>h5,
.cms-inner-wrapper ol li>h5 {
    text-transform: capitalize;
}

.cms-inner-wrapper .listing-highlited p,
.cms-inner-wrapper .listing-note p {
    font-size: 15px;
    line-height: 1.6;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    color: #666;
}

.cms-inner-wrapper .listing-note p span,
.cms-inner-wrapper .listing-highlited p {
    color: var(--theme-primary-color) !important;
}

.listing-subtitle {
    margin-bottom: 15px;
}

.list-style-none {
    list-style: none !important;
}

/* remuneration system page css =========================================================================  */
.remuneration-plan-wrapper {
    font-family: 'Poppins', sans-serif;
}

.remuneration-plan-wrapper p {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 1.6;
    font-weight: 400;
    color: #666;
}

/*tabing design css*/
.remuneration-plan-wrapper ul.nav-tabs .nav-item {
    list-style: none;
    margin: 0;
}

.remuneration-plan-wrapper ul.nav-tabs .nav-item:not(:last-child) {
    margin-right: 10px;
}

.remuneration-plan-wrapper ul.nav-tabs .nav-item .nav-link {
    font-size: 20px;
    line-height: 1;
    font-weight: 500;
    padding: 15px 20px;
    background-color: var(--white);
    border-radius: 15px 15px 0 0;
    border: 2px solid var(--theme-primary-color);
    color: var(--theme-primary-color);
}

.remuneration-plan-wrapper ul.nav-tabs .nav-item.show .nav-link,
.remuneration-plan-wrapper ul.nav-tabs .nav-item .nav-link.active {
    background-color: var(--theme-primary-color) !important;
    color: var(--white);
}

/* section wrapper css  */
.section-wrapper .listing-head h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: var(--theme-primary-color);
}

.content b,
.content p b,
.content-details p b {
    font-weight: 600 !important;
    text-transform: capitalize;
}

.content .section-sub-title {
    margin-bottom: 10px;
    color: var(--theme-primary-color);
}

.content ul {
    padding: 15px;
}

.content ul li {
    color: #fff;
    line-height: 20px;
}

/* highlightbox css  */
.highlight-box {
    padding: 10px 15px;
    margin: 10px auto;
}

.highlight-box h6 {
    font-size: 15px;
    line-height: 25px;
    font-weight: 500;
    color: #fff;
    text-transform: capitalize;
}

.highlight-box p {
    font-size: 15px;
    line-height: 24px;
}

.highlight-box p a {
    color: #fff;
}

/* remuneration banner css  */
.business-section,
.remuneration-section,
.premium-club-section,
.travel-club-section,
.leadership-bonus-section {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.remuneration-banner {
    position: relative;
    margin-bottom: 30px;
    margin-right: 20px;
}

.remuneration-banner::before {
    content: '';
    z-index: -1;
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: -20px;
    background-color: #BD3042;
    background-color: rgb(175, 12, 12, 0.09);
    right: -20px;
}

.remuneration-content {
    position: relative;
}

.remuneration-content .content-img {
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
}

.remuneration-banner {
    position: relative;
    margin-bottom: 30px;
    margin-right: 20px;
}

.bg-black {
    background-color: rgb(12 12 12 / 50%);
}

/* quote txt css  */
p.quto-txt {
    font-size: 18px !important;
    line-height: 28px !important;
    font-weight: 500 !important;
    color: #fff;
    font-style: italic;
}

p b.text-dark,
p.quto-txt.text-dark {
    color: #000 !important;
}

/* formula table css  */
.remuneration-datatable table tr th,
.remuneration-datatable table tr td {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    padding: 0.40rem;
}

.remuneration-datatable table thead tr th {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    vertical-align: middle;
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 1px;
}

.remuneration-datatable table tr.txt {
    color: #000;
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
}

.formula-table tr td b {
    font-weight: 500;
}

.formula-table tr td {
    color: #fff;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    text-transform: capitalize;
    vertical-align: middle;
    text-align: center;
}


/* naswiz-downloads page css =========================================================================  */

.download-box {
    border-radius: 6px;
    padding: 30px;
    margin-bottom: 30px;
    margin-top: 40px;
}

.download-box .download-icon {
    margin-top: -70px;
}

.download-box .download-icon i {
    background-color: #ffffff;
    border: 2px solid #bd3042;
    height: 80px;
    width: 80px;
    color: #bd3042;
    font-size: 42px;
    line-height: 80px;
    border-radius: 50px;
    margin-bottom: 20px;
}

.download-box .download-content h5 {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 500;
}

.naswiz-download-wrapper .download-box .theme-button {
    border-radius: 5px;
    padding: 10px 40px;
    cursor: pointer;
    display: inline-block;
}


/*consistency-offer page css =========================================================================  */

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

ol.offer-listing>li {
    margin-bottom: 15px;
    margin-left: 20px;
    font-weight: normal;
    position: relative;
    counter-increment: circle-counter;
    padding-left: 20px;
    list-style: none;
}


ol.offer-listing>li:before {
    content: counter(circle-counter);
    background-color: #d84757;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-block;
    font-size: 18px;
    line-height: 30px;
    color: #fff;
    text-align: center;
    top: 0px;
    left: -20px;
    position: absolute;
    z-index: -1;
}


/*blog details page css =========================================================================  */

.cms-page-title.line-effect::after,
.cms-page-title.line-effect::before,
.blog_page .blog-inner-wrapper .sub-heading::after {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.cms-page-title.line-effect::after,
.cms-page-title.line-effect::before {
    width: 45%;
    height: 1px;
    background-color: var(--light-gray);
    z-index: -1;
}

.cms-page-title.line-effect::after {
    right: 0;
}

.cms-page-title.line-effect::before {
    left: 0;
}


/* left right image effect css  */

.blog-imgarea.right {
    margin-left: 20px;
}

.blog-imgarea.right::before,
.blog-imgarea.left::before {
    position: absolute;
    content: '';
    z-index: -1;
    height: 100%;
    width: 100%;
    bottom: -20px;
    background-color: rgb(175, 12, 12, 0.09);
}

.blog-imgarea.left::before {
    right: -20px;
}

.blog-imgarea.right::before {
    left: -20px;
}


/* blog sub heading css  */

.blog-details-wrapper .blog-inside .blog-inner-wrapper {
    padding-left: 30px;
}

.blog_page .blog-inner-wrapper .sub-heading {
    padding-left: 40px;
    font-size: 22px;
    font-weight: 900;
}

.blog_page .blog-inner-wrapper .sub-heading::after {
    left: 0;
    width: 30px;
    height: 1px;
    background-color: var(--theme-primary-color);
}


/* blockquote css  */

.blog-details-wrapper blockquote {
    margin: 10px 0;
    line-height: 1.8;
    border-left: 4px solid var(--theme-primary-color);
    padding-left: 30px;
    font-family: 'Poppins', sans-serif;
    color: #666;
    font-size: 16px;
}

.blog-details-wrapper p {
    text-transform: capitalize;
    margin-bottom: 15px;
    font-size: 15px;
    line-height: 1.6;
    font-family: 'Poppins', sans-serif;
    color: #666;
}

.combinations-wrapper .box-area {
    box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 20%);
}

.combinations-wrapper .detailsarea {
    padding: 15px 10px;
}

.combinations-wrapper .detailsarea h4 {
    font-size: 16px;
}

.combinations-wrapper .box-area ul li {
    font-size: 14px;
    line-height: normal;
    color: #777;
}

.combinations-wrapper .box-area ul li+li {
    margin-top: 2px;
}


/*alkaline-ionizer page css =========================================================================  */

.alkaline-banner {
    padding-top: 130px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 80px;
}

.alkaline-banner-inner .banner-content h1 {
    font-size: 72px;
    font-weight: 700;
    line-height: 72px;
}

.alkaline-banner-inner .banner-content p {
    font-size: 18px;
    line-height: normal;
    margin: 20px 0;
}

.alkaline-banner-inner .banner-img {
    margin-bottom: -80px;
}


/* nav tab css  */

.alkaline-tabs.nav .nav-item .nav-link {
    font-size: 18px;
    padding: 15px 10px;
    color: var(--theme-secondary-color);
    text-transform: capitalize;
    height: 100%;
}

.alkaline-tabs.nav .nav-item .nav-link:hover,
.alkaline-tabs.nav .nav-item .nav-link.active {
    box-shadow: 0px 17px 40px 0px rgb(216 71 87 / 24%);
    -webkit-box-shadow: 0px 17px 40px 0px rgb(216 71 87 / 24%);
    color: var(--white);
    background-color: #d84757;
}


/* tab content css  */

.box-title h3 {
    font-size: 36px;
    font-weight: 800;
    margin-bottom: 30px;
}

.box-title p {
    font-size: 16px;
    color: var(--gray);
    margin-top: 10px;
}

.plate_alkaline5 .box-title h3 {
    color: #ed145b;
}

.plate_alkaline9 .box-title h3 {
    color: #41b83e;
}

.benifits-section .benifits-content h3 {
    color: #ff9000;
}

.how-to-use-section .box-title h3 {
    color: #ff0000;
}

.alkaline_certificates_section .box-title h3 {
    color: #00c0ff;
}

.manufacturing-section .box-title h3 {
    color: #cc00ff;
}

.testimonial-wrapper .box-title h3 {
    color: var(--theme-primary-color);
}


/* plate banner css  */

.plate-banner {
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat: no-repeat;
    display: inline-block;
    width: 100%;
}

.plate-banner .plate-banner-img {
    position: relative;
    z-index: 1;
}

.plate-banner .plate-shape {
    position: absolute;
    bottom: -2px;
    width: 100%;
}

.plate-banner .plate-banner-content h2 {
    font-size: 48px;
    font-weight: 800;
    line-height: 60px;
}


/* alkaline subtitle css  */

.alkaline_subtitle {
    margin-bottom: 20px;
}

.alkaline_subtitle h4 {
    font-size: 30px;
    font-weight: 600;
    text-transform: capitalize;
}


/* 9 & 5 Plate Alkaline Ionizer tab css  */

.plate_alkaline5 .product-details .table tr th,
.plate_alkaline9 .product-details .table tr th,
.plate_alkaline9 .product-details .table tr td,
.plate_alkaline5 .product-details .table tr td {
    border: 1px solid #ebebeb;
    color: var(--black);
    font-size: 18px;
    padding: 12px;
}

.plate_alkaline5 .product-details .table tr th {
    border-color: #ffadc8;
    background-color: #ffebf1;
    color: #ed145b;
    font-weight: 600;
}

.plate_alkaline9 .product-details .table tr th {
    border-color: #41b83e;
    background-color: #ebfdea;
    color: #41b83e;
    font-weight: 600;
}

.plate-info-box p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 25px;
}


/* Benefits of Naswiz Ionized Water tab css  */

.benifits-section .benifits-content .benifits-points li {
    font-size: 16px;
    color: #000000;
    padding-left: 35px;
    position: relative;
}

.benifits-section .benifits-content .benifits-points li+li {
    margin-top: 15px;
}

.benifits-section .benifits-content .benifits-points li::before {
    content: url("../assets/images/cms-page/point-icon.png");
    position: absolute;
    left: 0;
    top: 3px;
}


/* How to use the Iconized water tab css  */
.how-to-use-box+.how-to-use-box {
    margin-top: 20px;
}

.how-to-use-box .alkaline_subtitle {
    margin-bottom: 15px;
}

.how-to-use-box .alkaline_subtitle h4 {
    font-size: 26px;
}

.box-listing-details {
    margin-bottom: 20px;
}

.box-listing-details .box-listing-img {
    width: 100px;
}

.box-listing-details .box-listing-content {
    width: calc(100% - 100px);
    padding-left: 20px;
}

.box-listing-details .box-listing-content h4 {
    font-size: 20px;
    margin-bottom: 5px;
    text-transform: capitalize;
}

.box-listing-details .box-listing-content p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Alkaline Certificates tab css  */

.certificates-logo {
    margin-bottom: 30px;
}


/* Manufacturing Process tab css  */

.manufacturing-content ul {
    margin: 15px 0 0 15px;
    padding-left: 15px;
}

.manufacturing-section ul li {
    margin-bottom: 5px;
    list-style: disc;
    display: list-item;
    font-size: 14px;
    color: #979797;
}

.manufacturing-section ul li::marker {
    color: #d84757;
    font-size: 14px;
}


/* alkaline mobile view tab design css  */

.panel-group .panel {
    word-wrap: break-word;
    position: relative;
    background-color: #ffffff;
    border: 1px solid #dddddd;
}

.panel-group .panel+.panel {
    margin-top: 20px;
}

.panel-group .panel-heading .nav-link {
    font-size: 18px;
    line-height: 28px;
    padding-right: 30px;
    position: relative;
    color: var(--theme-primary-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.panel-group .panel-heading .nav-link[aria-expanded="true"] {
    background-color: var(--theme-primary-color);
    color: var(--white);
}

.panel-group .panel-heading .nav-link::after {
    position: absolute;
    content: "\f067";
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    font-family: 'fontawesome';
    font-size: 14px;
    text-align: center;
    background-color: var(--theme-primary-color);
    color: var(--white);
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 50%;
}

.panel-group .panel-heading .nav-link[aria-expanded="true"]::after {
    content: "\f068";
    background-color: var(--white);
    color: var(--theme-primary-color);
}


/* panel body css  */

.panel-group .panel .panel-body {
    background-color: #ffffff;
    padding: 15px;
}


/* phytowiz page css =========================================================================  */

.phytowiz-miracle-ingredients,
.power-phytowiz-ingredients,
.phytowiz-works,
.sublingual,
.how-to-use,
.recommendation-section,
.benefits-section,
.miracle-product {
    margin-top: 50px;
}

.phytowiz-banner {
    padding-top: 130px;
    margin-bottom: 80px;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: contain;
    min-height: 480px;
    background-color: #f4f4f4;
    position: relative;
    overflow: hidden;
}

.phytowiz-banner .phytowiz-banner-inner h1 {
    font-size: 36px;
    line-height: 40px;
}

.phytowiz-banner .phytowiz-banner-inner h1 span {
    color: var(--theme-secondary-color);
    font-size: 52px;
    line-height: 56px;
    display: block;
}

.power-phytowiz-ingredients {
    position: relative;
}

.ingredients-listing li {
    margin-bottom: 15px;
    align-items: center;
}

.phytowiz-product-img {
    width: 70px;
    height: 70px;
    display: contents;
}

.ingredients-listing li:first-child span {
    color: #47d6ba;
    font-weight: 600;
}

.ingredients-listing li p {
    line-height: 26px;
    font-size: 16px;
}

.ingredients-listing li:last-child span {
    color: #47a7d8;
    font-weight: 600;
}


/* phytowiz-works section css  */

.phytowiz-work-inner {
    position: relative;
}

.phytowiz-work-inner ul li {
    position: absolute;
    text-align: center;
}

.phytowiz-work-inner ul li span.numbering {
    font-size: 24px;
    font-weight: 700;
    display: block;
    position: relative;
}

span.numbering-1 {
    color: #47a7d8;
}

span.numbering-2 {
    color: #47d6ba;
}

span.numbering-3 {
    color: #d69947;
}

span.numbering-4 {
    color: #474fd6;
}

span.numbering-5 {
    color: #ce47d6;
}

span.numbering-6 {
    color: #7047d6;
}

.phytowiz-work-inner ul li span.workstep-content {
    font-size: 20px;
    color: #222529;
    font-weight: 400;
    text-align: center;
    margin: 0;
}

.phytowiz-work-inner ul li:nth-child(1) {
    top: 13%;
    left: 26%;
}

.phytowiz-work-inner ul li:nth-child(2) {
    top: 42%;
    left: 8%;
}

.phytowiz-work-inner ul li:nth-child(3) {
    top: 67%;
    left: 26%;
}

.phytowiz-work-inner ul li:nth-child(4) {
    top: 13%;
    left: 62%;
}

.phytowiz-work-inner ul li:nth-child(5) {
    top: 42%;
    right: 8%;
}

.phytowiz-work-inner ul li:nth-child(6) {
    top: 67%;
    left: 64%;
}

.sublingual ul li {
    position: relative;
    text-align: center;
    border: 1px solid rgb(235, 235, 235);
    background-color: rgb(255, 255, 255);
    padding: 40px;
    text-align: center;
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    margin-top: 20px;
}

.sublingual ul li span {
    position: absolute;
    right: 0;
    bottom: -15px;
    font-size: 52px;
    font-weight: 600;
    opacity: 0.2;
    line-height: normal;
}

.sublingual ul li:nth-child(1) {
    color: #d84757;
}

.sublingual ul li:nth-child(2) {
    color: #47a7d8;
}

.sublingual ul li:nth-child(3) {
    color: #47d6ba;
}

.use-box h4 {
    font-size: 18px;
    font-family: "Poppins";
    color: rgb(216, 71, 87);
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
}

.use-content h6 {
    font-size: 18px;
    color: #222529;
    margin-bottom: 5px;
}

.use-content p {
    font-size: 15px;
    color: #666666;
}

.recommendation-box {
    padding: 20px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
}

.recommendation-box img {
    margin-bottom: 5px;
    height: 50px;
}

.recommendation-box p {
    font-size: 18px;
    color: #222529;
    font-weight: 600;
    line-height: 28px;
    margin-top: 10px;
}

.benefits-section {
    position: relative;
}

.benefits-ul li {
    position: relative;
    padding-left: 45px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #070506;
    min-height: 35px;
    vertical-align: top;
}

.benefits-ul li::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    background-image: url("/assets/images/tick-mark.svg");
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
}

.miracle-section {
    position: relative;
    display: inline-block;
    padding-bottom: 100px;
}

.miracle-content {
    position: absolute;
    bottom: 30px;
    left: -30%;
}

.miracle-content span {
    position: absolute;
    top: 110px;
    left: 22px;
    color: #d84757;
    font-size: 19px;
    font-weight: 700;
}

.miracle-content a.btn {
    padding: 5px 15px;
    border: none;
    margin-top: -50px;
}

.miracle-product-listing li {
    display: inline-block;
    margin: 0 5px;
    width: auto;
}

.miracle-product .box-title p {
    font-size: 24px;
}

.miracle-product-title h4 {
    margin-bottom: 20px;
    font-size: 30px;
    color: var(--theme-primary-color);
}

.benefits-section::before,
.power-phytowiz-ingredients::before {
    background-color: var(--theme-primary-color);
    position: absolute;
    content: "";
    bottom: 0;
    width: 100%;
    height: 320px;
}

.benefits-section::before {
    right: 0;
}

.power-phytowiz-ingredients::before {
    left: 0;
}

.PhoneInputCountry {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.login_modal_number_box.logintext {
    max-width: 430px;
    margin: 0 auto;
    display: block;
    background: #fff;
    width: 100%;
    padding: 10px 0px 20px 0px;
}

.login_modal_number_box.logintext span {
    display: block;
    margin: 0 0 5px 0;
    color: #f00;
    font-size: 13px !important;
}

.login_modal_number_box.logintext span.subtitle {
    font-weight: 600;
}

.mobile-menu-container .social-icons {
    padding-left: 15px;
}

.adderess_inside {
    width: 100%;
    display: inline-block;
    padding: 10px 15px;
    text-align: center;
}

.editaddressModal .adderess_inside h4 {
    font-size: 18px !important;
    font-weight: 500 !important;
    margin-bottom: 8px !important;
    text-align: center;
    display: block;
}

.editaddressModal .adderess_inside p {
    font-size: 14px;
}

.profile_btn {
    padding: 6px 15px;
    border-radius: 3px;
    border: 1px solid #bd3042;
    font-size: 14px;
    font-weight: 400;
    margin: 0px auto;
    background-color: var(--theme-primary-color);
}

.profile_btn:hover {
    background-color: var(--dark);
    border-color: var(--dark);
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: none;
}

.default-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
}

.default-div span {
    font-size: 21px;
    color: #000;
}

.default-div p {
    font-size: 19px;
    margin: 0px 0 20px 0;
}

.default-div button,
.default-div a {
    background: #bd3042;
    border: none;
    color: #fff;
    padding: 9px 20px 9px 20px;
    font-size: 17px;
    cursor: pointer;
    display: inline-block;
}

.product-wrapper-grid .row {
    margin-left: -10px;
    margin-right: -10px;
}

.product-wrapper-grid .col-6,
.product-wrapper-grid .col-md-4,
.product-wrapper-grid .col-sm-4,
.product-wrapper-grid .col-xl-3 {
    padding-left: 10px;
    padding-right: 10px;
}

.payment-file input {
    margin-bottom: 0;
    padding: 10px;
}

.input-group .input-group-text {
    background-color: var(--theme-primary-color);
    color: var(--white);
    border: none;
    cursor: pointer;
}

.input-group .input-group-text:hover {
    background-color: var(--theme-secondary-color);
}

.payment-radio-design.checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.payment-radio-design.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.payment-radio-design.checkbox .radio-element {
    position: absolute;
    top: 4px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #d9d9d9;
}

.payment-radio-design.checkbox:hover input~.radio-element {
    background-color: #ccc;
}

.payment-radio-design.checkbox input:checked~.radio-element {
    background-color: var(--theme-primary-color);
}

.payment-radio-design.checkbox .radio-element:after {
    content: "";
    position: absolute;
    display: none;
}

.payment-radio-design.checkbox input:checked~.radio-element:after {
    display: block;
}

.payment-radio-design.checkbox .radio-element:after {
    left: 9px;
    top: 5px;
    width: 8px;
    height: 13px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.payment-radio-design.radio {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.payment-radio-design.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;
    z-index: 5;
}

.addmany .select-payment-method .payment-radio-design.radio input {
    width: 100%;
    height: 67px;
    top: -10px;
    margin: 0;
}

.payment-radio-design.radio .radio-element {
    position: absolute;
    top: 4px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #d9d9d9;
    border-radius: 50%;
}

.payment-radio-design.radio:hover input~.radio-element {
    background-color: #ccc;
}

.payment-radio-design.radio input:checked~.radio-element {
    background-color: var(--theme-primary-color);
}

.payment-radio-design.radio .radio-element:after {
    content: "";
    position: absolute;
    display: none;
}

.payment-radio-design.radio input:checked~.radio-element:after {
    display: block;
}

.payment-radio-design.radio .radio-element:after {
    top: 7px;
    left: 7px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: white;
}

.payment-radio-design .wallet-type {
    display: flex;
}

.payment-radio-design .wallet-type p {
    padding-left: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #233266;
}

.payment-radio-design .wallet-type p.available-balance {
    font-size: 13px;
    margin-top: 4px;
    color: #6a6a6a;
    font-weight: 500;
}

.payment-radio-design .wallet-type p span {
    color: #6a6a6a;
}

.payment-radio-design .wallet-type i {
    color: #233266;
    font-size: 38px;
}

.card-selection__details .wallet_name {
    display: none;
}

.wallet-type img {
    max-height: 25px;
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option-selected {
    border-left-color: var(--theme-primary-color) !important;
}

.input-row .single-input-item input[type="file"] {
    height: auto;
    padding: 9px 20px;
}

.main.payment-response {
    padding-bottom: 0px;
    padding-top: 20px;
}

.payment-response .listing-content h4 {
    font-size: 20px;
    margin-bottom: 15px;
}

.payment-response .listing-content h4 span {
    font-size: 12px;
    display: block;
    font-weight: 400;
}

.payment-response hr {
    margin-top: 10px;
    margin-bottom: 10px;
}

.payment-response .col-6 label {
    margin-bottom: 0;
}

.inside_ticket {
    padding: 15px;
    background-color: #ebebeb;
}

.inside_ticket .orderissue-header {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.inside_ticket .orderissue-header h2 {
    margin-bottom: 0;
    font-size: 20px;
}

.inside_ticket .orderissue-header span {
    margin-left: auto;
    background-color: var(--theme-primary-color);
    padding: 4px 10px;
    color: #fff;
    cursor: pointer;
}

.col-md-12:last-child .orderissue-box {
    border-bottom: none;
}

.inside_ticket .orderissue-box {
    padding: 8px 0;
    font-size: 14px;
    display: flex;
    border-bottom: 1px solid rgb(218, 218, 218);
}

.inside_ticket .orderissue-box span {
    margin-right: 5px;
    color: #000;
    width: 25%;
}

.inside_ticket .orderissue-box label {
    margin-bottom: 0;
    font-weight: 600;
    width: 75%;
    word-break: break-all;
}

.inside_ticket .orderissue-box label a {
    color: #0073b2;
}

.wallet-transaction {
    margin-bottom: 10px;
    font-size: 18px;
    margin-top: 20px;
}

.trans_details {
    display: flex;
    align-items: center;
}

.trans_details_left {
    position: relative;
}

.trans_details_left .refund {
    padding-left: 45px;
}


/* sidebar */


.side-navigation-panel-select-inner-option {
    font-size: 14px;
    width: 100%;
    line-height: 30px !important;
}

.dashboard-left .block-content ul li:before {
    display: none !important;
}

.dashboard-left .block-content {
    padding: 0 !important;
}

.side-navigation-panel-select {
    margin-bottom: 0 !important;
}

.side-navigation-panel-select-option {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    line-height: 37px !important;
}

.border-pink-500,
.hover\:border-pink-500:hover {
    /* border-color: rgba(237,100,166,var(--border-opacity)); */
    /* border-color: inherit!important; */
    border-color: #bd3042 !important;
}

.dashboard-left .block-content ul li:hover {
    padding-left: 0px !important;
    color: inherit !important;
    transition: none !important;
}

.actions_btns_list a {
    color: #bd3042 !important;
}

.actions_btns_list a:hover {
    color: white !important;
}

/* Address Modal */

.addressModal,
.editaddressModal {
    margin: 0 !important;
    display: block !important;
    max-width: 100% !important;
}

.inside_form_fields {
    overflow-x: hidden;
}

.addressModal .inside_form_fields .row,
.addressModal .inside_form_fields .address_type,
.addressModal .inside_form_fields .both_buttons {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.addressModal .inside_form_fields .address_type,
.addressModal .inside_form_fields .both_buttons {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.cancel_btn_black {
    border-radius: 3px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.addressModal .modal-content {
    padding-bottom: 10px;
    border-radius: 5px;
    background: #f9f9f9;
}

.addressModal .adderess_inside {
    background: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

/* sidebar-end */


.select-payment-method {
    padding: 15px;
}

.select-payment-method .form-check {
    border: solid 1px rgb(202, 202, 202);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.select-payment-method .form-check p {
    font-size: 12px;
    padding-left: 30px;
    padding-top: 5px;
    font-weight: 400;
}

.select-payment-method .payment-radio-design span {
    padding: 0 10px 0 0;
}

.select-payment-method .payment-radio-design label img {
    max-height: 25px;
}

.select-payment-method .form-check-label {
    cursor: pointer;
}

.infoslider_with_icons .slick-list {
    overflow: hidden;
}

.infoslider_with_icons .info-box {
    border-right: none;
}

.infoslider_with_icons .border-right-slider {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    -ms-flex-align: center;
    justify-content: center;
    -ms-flex-pack: center;
    padding: 25px 24px 20px;
    width: 100%;
    border-right: 1px solid #e7e7e7;
    margin-right: -3px;
}

.address_box {
    position: relative;
    padding-left: 30px;
}

.address_box i {
    position: absolute;
    left: 0;
    top: 1px;
    width: 20px;
    text-align: center;
}

.address_box p {
    font-size: 14px;
}

input[type=number] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.address-form .form-group label {
    margin-bottom: 1px;
}

.address-form .form-group {
    margin-bottom: 10px;
}

.address-form .form-group .form-control {
    margin-bottom: 0;
}

/* sms message login css  */
h4.login_modal_title i.fas.fa-sms {
    background-color: #bd3042 !important;
    color: #fff;
    font-size: 38px;
    line-height: 38px;
    padding: 10px;
    border-radius: 100px;
    width: 58px;
    margin: 0 10px 0 0;
}

/* order not returnable modal css  */
.cancel_order_modal.not-cancel .logo {
    text-align: center;
    margin: 0 auto 10px auto;
}

.cancel_order_modal.not-cancel h1 {
    font-size: 24px;
    line-height: normal;
    text-transform: capitalize;
}

.cancel_order_modal.not-cancel .product-filter {
    font-size: 15px;
    color: var(--theme-secondary-color);
}

.cancel_order_modal.not-cancel ul {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cancel_order_modal.not-cancel ul li {
    text-align: center;
    margin: 0 15px;
}

.cancel_order_modal.not-cancel ul li label {
    color: var(--theme-primary-color);
    font-size: 16px;
    line-height: normal;
    font-weight: 600;
    display: block;
    margin-bottom: 0;
}

.cancel_order_modal.not-cancel ul li label i {
    width: 15px;
}

.cancel_order_modal.not-cancel ul li a {
    color: var(--theme-secondary-color);
    font-size: 15px;
}

/* add css  */
.ant-modal-content .table-responsive {
    max-height: 400px;
}

.ant-modal-content .table th {
    position: sticky;
    top: 0px;
}

.shipping_details,
.shipping_address_value {
    padding-top: 20px;
    padding-bottom: 20px;
}

/* responsive-css =========================================================================*/

@media (min-width:2000px) and (max-width:2800px) {

    /* phytowiz page responsive css */
    .power-phytowiz-ingredients::before {
        width: 36%;
    }
}

@media (min-width:1400px) and (max-width:2000px) {

    /* phytowiz page responsive css */
    .power-phytowiz-ingredients::before {
        width: 30%;
    }
}

@media (min-width:992px) and (max-width:1400px) {

    /* phytowiz page responsive css */
    .power-phytowiz-ingredients::before {
        width: 22%;
    }
}

@media (min-width:992px) and (max-width:1100px) {
    .sidebar-shop .top-bar .btn {
        width: 100%;
    }

    .sidebar-shop .top-bar .btn+.btn {
        margin-top: 10px;
    }
}

@media (min-width:1700px) and (max-width:2800px) {

    /* phytowiz page responsive css */
    .benefits-section::before {
        width: 32%;
    }
}

@media (min-width:1200px) and (max-width:1700px) {

    /* phytowiz page responsive css */
    .benefits-section::before {
        width: 32%;
    }
}

@media (min-width:992px) and (max-width:1200px) {

    /* phytowiz page responsive css */
    .phytowiz-banner.alkaline-banner {
        padding-top: 80px;
    }

    .benefits-section::before {
        width: 22%;
    }
}

@media (min-width: 1200px) {
    .cart-dropdown:hover .dropdown-menu {
        top: 100%;
        opacity: 1;
        visibility: visible;
    }

    body .main-nav .menu>li:hover .megamenu {
        left: unset !important;
        display: block !important;
    }

    body .main-nav .menu>li .megamenu.megamenu-6cols {
        left: 10% !important;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }
}

@media only screen and (max-width: 1199px) {
    .main-nav .menu>li {
        margin-right: 11px !important;
    }

}

@media only screen and (min-width: 992px) {

    .dropdown-expanded>a,
    .dropdown-expanded .header-menu:before {
        display: none;
    }

    .w-lg-max {
        flex: 1;
        -ms-flex: 1;
        max-width: 100%;
    }

    .header-search-inline.header-search .header-search-wrapper {
        display: flex;
        display: -ms-flex;
        position: static;
        margin: 0;
        border-width: 0;
        background-color: #f4f4f4;
    }

    .banner {
        position: relative;
        font-size: 1.6rem;
    }

    .header-search-inline .search-toggle,
    .header-search-inline .btn:after {
        display: none;
    }

    .sidebar-toggle {
        display: none;
    }

    .dashboard-content {
        margin-bottom: 1em;
    }

    .dashboard-content h2 {
        margin-bottom: 20px;
        font-size: 24px;
    }

    .header_active .header-middle {
        padding: 20px;
    }

    /* coantact page css  */
    .contact-page .contact-right ul li+li {
        margin-top: 20px;
    }

    /* alkaline-ionizer page responsive css */
    .plate-banner {
        margin-top: 80px;
    }

    .plate-banner .plate-banner-img {
        margin-top: -80px;
    }

    /* phytowiz page responsive css */
    .benefits-section::before,
    .power-phytowiz-ingredients::before {
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
    }

}

@media only screen and (min-width: 557px) {

    .register_modal,
    .login_modal {
        width: 568px;
    }
}

@media only screen and (max-width: 1199px) {
    .container {
        max-width: 100%;
    }
}

@media only screen and (max-width: 991px) {
    .container {
        max-width: none;
    }

    section {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .mobile-menu-toggler {
        display: block;
    }

    .header-middle {
        padding: 20px 0;
    }

    .dropdown-toggle i {
        font-size: 25px;
    }

    .header-icon .icon-wishlist-2 {
        font-size: 25px;
    }

    .search-toggle .icon-search-3 {
        font-size: 25px;
    }

    .header-icon {
        font-size: 22px;
        margin-right: 12px;
    }

    .cart-dropdown.cart {
        min-width: auto;
        font-size: 12px;
    }

    .header-middle a.icon-search-three {
        display: none;
    }

    .user_log_dp {
        min-width: auto;
    }

    .header .separator {
        display: none;
    }

    .header-top p {
        font-size: 10px;
    }

    .promo-section {
        padding: 20px 0;
    }

    .promo-section h4 {
        margin-bottom: 10px !important;
    }

    .get_fred {
        font-size: 14px !important;
        line-height: normal;
        margin-bottom: 10px !important;
    }

    .promo-section .right-content span {
        font-size: 16px;
        line-height: 30px;
    }

    .promo-section .right-content p {
        font-size: 20px;
        line-height: 30px;
    }

    .promo-section .right-content .bth_de {
        font-size: 16px !important;
        padding: 0 10px 0px 10px;
        line-height: 28px;
    }

    .coupon-sale-text b {
        font-size: 13px;
    }

    .new-products-section .owl-carousel.nav-image-center .owl-nav button {
        top: 35%;
        position: absolute;
        z-index: 9;
        font-size: 30px;
    }

    .new-products-section .owl-carousel.nav-image-center .owl-nav button.owl-prev {
        left: 10px;
    }

    .new-products-section .owl-carousel.nav-image-center .owl-nav button.owl-next {
        right: 10px;
    }

    .new-products-section .owl-carousel.nav-image-center .owl-nav:hover {
        background-color: transparent;
    }

    .banner-big-sale h2 {
        font-size: 12px;
    }

    .banner-big-sale h2 small {
        font-size: 12px;
    }

    .banner-big-sale .btn {
        padding: 13px 30px;
    }

    .header-search {
        margin-right: 12px;
    }

    .header-search+.box {
        display: none;
    }

    .header-search.show+.box {
        display: block;
    }

    .header-search.show+.box+a.icon-search-three {
        display: block;
    }

    .header-search+.box {
        position: absolute;
        right: 0;
    }

    .header-icon+.box {
        position: absolute;
        right: 0;
    }

    .header-right.w-lg-max input {
        padding-right: 64px;
    }

    .sidebar-opened .mobile-sidebar {
        transform: none;
    }

    .mobile-sidebar {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        width: 320px;
        padding: 16px;
        margin: 0;
        transform: translate(-320px);
        transition: transform .2s ease-in-out 0s;
        background-color: var(--white);
        z-index: 9999;
        border-right: 1px solid #dddddd;
        overflow-y: auto;
    }

    .product-single-details .product-desc+button {
        font-size: 14px;
    }

    .product-single-filter label {
        font-size: 13px;
    }

    .product-single-filter .config-size-list li a {
        min-width: 27px;
        height: 27px;
        line-height: 27px;
    }

    .product-single-filter .config-size-list li:not(:last-child) {
        margin-right: 3px;
    }

    .custom-row span.title {
        font-size: 24px;
    }

    .dashboard-left .block-content {
        margin-top: 0;
        margin-bottom: 20px;
    }

    .user_log_dp {
        margin-right: 12px;
    }

    .header-middle .box button.pulse {
        top: 37px;
        right: -3px;
        width: 30px;
        height: 30px;
        z-index: 999;
        position: absolute;
    }

    .header-middle a.icon-search-three {
        right: -20px;
        top: 45px;
        line-height: 38px;
        z-index: 999;
    }

    .success-text h2 {
        font-size: 22px;
    }

    .success-text p.small-title {
        font-size: 18px;
        margin-bottom: 10px;
    }

    section.section-b-space.light-layout {
        min-height: auto;
    }

    .slick-next {
        right: 0;
    }

    .slick-prev {
        left: 0;
    }

    .process_cart ul li:nth-child(1)::after,
    .process_cart ul li:nth-child(2)::after {
        display: none;
    }

    .process_cart {
        margin-top: 10px;
    }

    .mobile-menu li a {
        font-size: 13px;
    }

    /* coantact page css  */
    .contact-page .contact-right ul li {
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .contact-page .contact-right ul li .media-body {
        padding-left: 0;
    }

    .contact-page .contact-right ul li .media-body::before {
        content: none;
    }

    /* alkaline-ionizer page responsive css */
    .plate-banner {
        margin-top: 60px;
    }

    .plate-banner .plate-banner-img {
        margin-top: -60px;
    }

    .plate-banner .plate-banner-content h2 {
        font-size: 42px;
    }

    .box-listing-details .box-listing-img {
        width: 80px;
    }

    .box-listing-details .box-listing-content {
        width: calc(100% - 80px);
        padding-left: 15px;
    }

    .box-listing-details {
        margin-bottom: 20px;
    }

    .box-listing-details .box-listing-content h4 {
        font-size: 18px;
        margin-bottom: 5px;
    }

    .box-listing-details .box-listing-content p {
        -webkit-line-clamp: 2;
    }

    /* phytowiz page responsive css */
    .how-to-use {
        text-align: center;
    }

    .use-box .d-flex {
        justify-content: center;
        text-align: left;
    }

    .power-phytowiz-ingredients::before {
        width: 100%;
        bottom: 0;
    }

    .benefits-section::before,
    .power-phytowiz-ingredients::before {
        height: 180px;
    }

    .blog-section {
        padding-top: 30px;
    }

    .top_left_t p {
        padding-bottom: 8px;
    }

    .block_edit_add,
    .totalCalcualte {
        padding: 15px;
        padding-top: 0;
    }

    .referal_code {
        padding-left: 15px;
    }

    .payment_tab {
        padding-left: 15px;
        padding-right: 15px;
    }

    .couter_list::before {
        width: 22px;
        height: 22px;
        line-height: 20px;
        left: 15px;
    }

    .payment_tab .nav-tabs .nav-item a {
        font-size: 14px;
    }

    .payment-radio-design span:before {
        width: 15px;
        height: 15px;
    }

    .couter_list.active h4,
    .address_checkout,
    .couter_list h4 {
        padding-left: 50px;
    }

    .wallet-type img {
        max-height: 20px;
    }

    .indivisual_pay {
        padding: 15px 0;
    }

    .payment-radio-design input[type='radio']:checked+span:before {
        box-shadow: inset 0 0 0 5px var(--theme-primary-color)
    }

    .product-default figure .product-action {
        visibility: visible;
        opacity: .85;
    }

    .product-default figure .btn-quickview {
        visibility: visible;
        opacity: 1;
    }

    .payment-response .listing-content h4 {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .payment-response .listing-content h4 span {
        font-size: 11px;
        display: block;
        font-weight: 400;
    }

    .payment-response {
        font-size: 13px;
    }

    .refund_amt {
        width: 130px;
    }

    .refund p {
        font-size: 14px;
    }

    body .search_drp {
        width: 380px !important;
        left: 20px !important;
        top: 93px !important;
        display: none !important;
        transform: translateX(-70%);
        -webkit-transform: translateX(-70%);
    }

    /* slider nav css */
    .slick-prev,
    .slick-next {
        width: 20px;
        height: 20px;
    }

    .nav-big .slick-next:before,
    .nav-big .slick-prev:before {
        font-size: 16px;
    }
}

@media (max-width: 991px) {
    .alkaline-banner {
        padding-top: 50px;
    }

    .alkaline-banner-inner .banner-content h1 {
        font-size: 42px;
        line-height: 42px;
    }

    .alkaline-banner-inner .banner-content p {
        margin: 10px 0 20px 0;
    }

    .alkaline-banner-inner .banner-content {
        margin-bottom: 20px;
    }

    .phytowiz-banner {
        padding-top: 50px;
    }
}

@media (min-width:767px) and (max-width:992px) {

    /* coantact page css  */
    .contact-page .contact-right ul {
        justify-content: space-between;
        flex-direction: row !important;
        align-items: baseline !important;
    }

    .contact-page .contact-right ul li {
        width: 33%;
    }

    .contact-page .contact-right ul li:nth-child(2) {
        border-left: 1px solid #dee2e6;
        border-right: 1px solid #dee2e6;
    }

    /* phytowiz page responsive css */
    .phytowiz-work-inner ul li span.workstep-content {
        font-size: 16px;
    }

    .phytowiz-work-inner ul li:nth-child(1),
    .phytowiz-work-inner ul li:nth-child(4) {
        top: 11%;
    }

    .phytowiz-work-inner ul li:nth-child(2) {
        top: 37%;
        left: 7%;
    }

    .phytowiz-work-inner ul li:nth-child(5) {
        top: 37%;
        right: 6%;
    }

    .phytowiz-work-inner ul li:nth-child(3),
    .phytowiz-work-inner ul li:nth-child(6) {
        top: 63%;
    }
}

@media (min-width: 767px) {

    /* store location page css */
    .state-box .state-img h2 {
        font-size: 30px;
    }

    /* phytowiz page responsive css */
    .recommendation-box {
        min-height: 163px;
    }

    .benefits-ul li {
        display: inline-block;
        width: 50%;
    }

    .editaddressModal.vertical-align-center {
        width: 750px;
    }

    /* remuneration page css  */
    .content.right-content {
        position: absolute;
        left: 15px;
        z-index: 1;
        top: 0;
        width: 50%;
        background-color: rgba(0, 0, 0, .8);
        padding: 15px 15px 15px 15px;
    }
}

@media only screen and (max-width: 767px) {
    .product-single-details .product-title {
        font-size: 18px;
        line-height: 24px;
    }

    .product-action.custom-design input.quantity {
        width: 36px;
    }

    body .product-action.custom-design .number-input.number-input button {
        width: 25px;
    }

    .social-icon {
        width: 24px;
        height: 24px;
        line-height: 22px;
        font-size: 12px;
        text-align: left;
    }

    .icon-whatsapp:before {
        font-size: 15px;
    }

    body .custom-row {
        flex-wrap: wrap;
    }

    .custom-row .review-start {
        margin-bottom: 15px;
        flex-direction: column;
    }

    .custom-row .review-start span {
        font-size: 15px;
    }

    .grid_row_address .grid_content {
        width: 100%;
    }

    .grid_row_address {
        display: block;
    }

    .grid_row_address .actions_btns {
        padding-top: 5px;
    }

    .my_profile_inside {
        width: 100%;
        display: inline-block;
        background-color: #eee;
        padding: 30px;
        min-height: auto;
        margin-top: 20px;
    }

    .order_history table thead tr th:nth-child(1) {
        width: 220px;
        display: block;
    }

    .order_history table thead tr th {
        word-wrap: break-word;
        min-width: 220px;
        max-width: 220px;
        border: none;
    }

    .allImg {
        display: block;
        justify-content: space-around;
        margin: 20px 0px;
    }

    .allImg img {
        margin: 20px auto;
        height: auto;
    }

    .ticket_list h3 {
        width: 100%;
    }

    .ticket_list .add_ticket {
        width: 100%;
        display: inline-block;
        text-align: left;
    }

    .ticket_id,
    .issue_subject {
        width: 100%;
    }

    .pending_issue,
    .issue_date {
        width: 100%;
        text-align: left;
    }

    .refund_date p {
        padding-left: 40px;
    }

    .refund_date {
        padding-left: 0;
    }

    .trans_logo_container {
        width: 25px;
    }

    .note_area {
        text-align: center;
        width: 100%;
        margin: 30px auto;
    }

    .left_txt_crt h4 {
        font-size: 16px;
    }

    .inside_crt .media_cart {
        width: 80px;
    }

    .whole_cart_left .inside_crt .list_iten_cart {
        width: calc(100% - 80px);
    }

    .inside_crt .increase_product.circle_mp {
        width: 100%;
        margin-left: 90px;
        margin-top: 10px;
    }

    .process_cart ul li a {
        font-size: 14px;
    }

    .cart-dropdown .dropdown-menu,
    .compare-dropdown .dropdown-menu {
        width: 340px;
    }

    .toolbox label {
        display: none;
    }

    .breadcrumb-item+.breadcrumb-item {
        padding-left: 5px;
    }

    .breadcrumb-item+.breadcrumb-item::before {
        padding-right: 5px;
    }

    h4.login_modal_title {
        font-size: 16px;
    }

    .styles_modal__gNwvD {
        max-width: 100% !important;
    }

    .login_modal_number_box p,
    .register_modal_number_box p {
        font-size: 14px !important;
        margin: 0 0 8px 0;
        text-transform: capitalize;
    }

    .dropdown-arrow::before {
        padding-right: 0;
    }

    .dropdown-arrow::before {
        display: none;
    }


    .product-single-share .icon-whatsapp:before {
        font-size: 14px;
    }

    .product-single-share .social-mail {
        line-height: 20px;
    }

    .content_inside {
        padding: 20px;
    }

    body .verified-number,
    body .otp_verification {
        padding: 20px;
    }

    .next_btn_check {
        max-width: 100%;
        margin-left: 0;
    }

    .login_btn_check {
        margin-right: 0;
        margin-bottom: 5px;
    }


    .icon-heart.add-wishlist:before,
    .product-single-share .fa-heart::before {
        width: 24px;
        height: 24px;
        font-size: 14px;
        line-height: 24px;
    }

    a.icon-heart.add-wishlist span {
        line-height: 23px;
    }

    .listing-head h2 {
        font-size: 22px;
    }

    .ticket_id,
    .issue_subject {
        margin-top: 10px;
    }

    .pending_issue,
    .issue_date p {
        padding-bottom: 0;
    }

    .ticket_listing p {
        font-size: 14px;
        padding-bottom: 5px;
    }

    .ticket_id {
        margin-top: 0;
    }

    .notification hr {
        margin: 0 !important;
    }

    body .number-input.number-input button {
        width: 22px;
        height: 22px;
    }

    .cart_left {
        width: 73px;
    }

    .cart_middle {
        width: 40px;
    }

    .combine_prices .product-price {
        margin-right: 0;
    }

    /* comman margin padding 30px class  */
    .pt-30 {
        padding-top: 15px;
    }

    .pb-30 {
        padding-bottom: 15px;
    }

    .mt-30 {
        margin-top: 15px;
    }

    .mb-30 {
        margin-bottom: 15px;
    }

    /* breadcum responsive css  */
    .breadcrumb-section {
        padding: 30px 0;
    }

    /* coantact page css  */
    .contact-page .contact-right ul li:not(:last-child) {
        border-bottom: 1px solid #dee2e6;
        padding-bottom: 15px;
        margin-bottom: 15px;
    }

    .contact-icon i,
    .contact-icon .fa-2x {
        font-size: 20px !important;
    }

    /* store location page css */
    .state-box .state-img h2 {
        font-size: 20px;
    }

    /* cms page responsive css  */
    .cms-page-title h2 {
        font-size: 24px;
    }

    .listing-content h4 {
        font-size: 18px;
    }

    .cms-inner-wrapper ul li,
    .cms-inner-wrapper ol li {
        margin-left: 20px;
    }

    /* remuneration page css  */
    .content .section-sub-title {
        margin: 10px 0 5px 0;
    }

    .content.right-content .text-white {
        color: #000 !important;
    }

    .content.right-content ul li {
        color: #000 !important;
    }

    p.quto-txt {
        font-size: 15px !important;
        line-height: 24px !important;
    }

    .remuneration-plan-wrapper p {
        font-size: 14px;
        line-height: normal;
    }

    .highlight-box {
        padding: 10px;
    }

    .highlight-box h6 {
        font-size: 14px;
        line-height: 22px;
    }

    /* blog inner details page css  */
    .blog_page .blog-inner-wrapper {
        margin-top: 15px;
    }

    .blog_page .blog-inner-wrapper .sub-heading {
        font-size: 18px;
    }

    .blog-details-wrapper blockquote,
    .blog-details-wrapper .blog-inside .blog-inner-wrapper {
        padding-left: 15px;
    }

    .blog-imgarea.left {
        margin: 10px 15px 10px 0;
    }

    .blog-imgarea.right {
        margin: 10px 0 10px 15px;
    }

    .blog-imgarea.right::before,
    .blog-imgarea.left::before {
        bottom: -10px;
    }

    .blog-imgarea.left::before {
        right: -15px;
    }

    .blog-imgarea.right::before {
        left: -15px;
    }

    /* alkaline-ionizer page responsive css */
    .box-title h3 {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 15px;
    }

    .alkaline_subtitle {
        margin-bottom: 10px;
    }

    .alkaline_subtitle h4 {
        font-size: 20px;
        line-height: 24px;
    }

    .plate-info-box p {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 15px;
    }

    .plate-banner .plate-banner-content h2 {
        font-size: 27px;
        line-height: 34px;
    }

    .plate_alkaline5 .product-details .table tr th,
    .plate_alkaline9 .product-details .table tr th,
    .plate_alkaline9 .product-details .table tr td,
    .plate_alkaline5 .product-details .table tr td {
        font-size: 16px;
        padding: 8px;
    }

    /* phytowiz page responsive css */
    .miracle-product-listing {
        flex-wrap: wrap;
        justify-content: center;
    }

    .sublingual ul li {
        text-align: left;
        padding: 15px;
        font-size: 18px;
    }

    .sublingual ul li span {
        bottom: -9px;
        font-size: 30px;
    }

    .phytowiz-banner .phytowiz-banner-inner h1 {
        font-size: 28px;
    }

    .phytowiz-banner .phytowiz-banner-inner h1 span {
        font-size: 35px;
        line-height: 40px;
    }

    .phytowiz-work-inner ul li span.workstep-content {
        display: none;
    }

    .phytowiz-work-inner ul li span.numbering:hover::before {
        position: absolute;
        top: 30px;
        left: 0;
        background-color: #fff;
        box-shadow: 0px 17px 40px 0px rgba(129, 127, 128, 0.24);
        -webkit-box-shado: 0px 17px 40px 0px rgba(129, 127, 128, 0.24);
        content: attr(title);
        border-radius: 0px 10px 10px 10px;
        padding: 10px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        width: 150px;
        z-index: 3;
    }

    .phytowiz-work-inner ul li:nth-child(4) span.numbering:hover::before,
    .phytowiz-work-inner ul li:nth-child(5) span.numbering:hover::before,
    .phytowiz-work-inner ul li:nth-child(6) span.numbering:hover::before {
        right: 0;
        left: auto;
    }

    .miracle-content {
        left: -50px;
    }

    .footer-bottom .text-right {
        text-align: center !important;
        margin-top: 5px;
    }

    .trans_details_left .refund {
        padding-left: 35px;
    }

    .inside_ticket .orderissue-box {
        font-size: 13px;
    }

    .inside_ticket .orderissue-box {
        display: block;
    }

    .inside_ticket {
        margin-top: 10px;
    }

    .inside_ticket .orderissue-box span {
        width: 100%;
        display: block;
    }

    .inside_ticket .orderissue-box label {
        width: 100%;
    }

    .dropdown-arrow .badge-circle {
        left: 10px;
        top: -12px;
    }

    .available_items a p {
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: 0;
        width: 100%;
        height: 20px;
        white-space: nowrap;
    }

    .payment-radio-design.radio .radio-element {
        width: 20px;
        height: 20px;
        top: 7px;
    }

    .payment-radio-design.radio {
        padding-left: 30px;
    }

    .payment-radio-design.checkbox .radio-element {
        width: 20px;
        height: 20px;
    }

    .payment-radio-design.checkbox {
        padding-left: 30px;
    }

    .card-selection__details {
        padding-left: 30px;
    }

    .payment-radio-design.radio .radio-element:after {
        top: 5px;
        left: 5px;
        width: 10px;
        height: 10px;
    }

    .payment-radio-design.checkbox .radio-element:after {
        left: 6px;
        top: 2px;
    }

    .payment-radio-design .wallet-type i {
        font-size: 28px;
    }

    .input-row .single-input-item {
        padding: 0px 0px 20px 0px;
    }

    .other_method {
        padding: 20px 0;
    }

    .offer_div {
        display: block;
    }

    .offer_details {
        width: 100%;
        display: block;
    }

    .offer_logo {
        width: 35px;
        float: left;
    }

    .offer_link {
        width: 100%;
        margin-top: 15px;
        text-align: left;
        padding-left: 45px;
    }

    .offer_para {
        padding-left: 45px;
        margin-left: 0;
        font-size: 14px;
    }

    .offer_link a {
        padding: 4px 10px;
    }

    .dropdown-arrow {
        padding-right: 0;
    }

    .notifications_inside {
        padding: 15px;
    }
}

@media (min-width:420px) and (max-width:767px) {

    /* phytowiz page responsive css */
    .phytowiz-work-inner ul li:nth-child(1),
    .phytowiz-work-inner ul li:nth-child(4) {
        top: 16%;
    }

    .phytowiz-work-inner ul li:nth-child(1),
    .phytowiz-work-inner ul li:nth-child(3) {
        left: 30%
    }

    .phytowiz-work-inner ul li:nth-child(3),
    .phytowiz-work-inner ul li:nth-child(6) {
        top: 70%;
    }

    .phytowiz-work-inner ul li:nth-child(4),
    .phytowiz-work-inner ul li:nth-child(6) {
        right: 27%
    }

    .phytowiz-work-inner ul li:nth-child(2) {
        top: 45%;
        left: 10%
    }

    .phytowiz-work-inner ul li:nth-child(5) {
        top: 45%;
        right: 10%
    }

}

@media (min-width: 575px) {

    /* phytowiz page responsive css */
    .phytowiz-banner::after {
        position: absolute;
        content: url("/assets/images/banner-image2.png");
        right: 0;
        bottom: -5px;
    }

    .top-order-view .checkout-title {
        margin-right: 20px;
        padding-right: 20px;
        border-right: 2px solid #dddddd;
    }
}

@media (max-width: 575px) {
    .top-order-view {
        flex-direction: column;
        align-items: baseline;
    }

    .top-order-view .order_status {
        margin: 5px 0;
    }

    .top-order-view .order-date {
        margin-left: 0px !important;
    }

    /* remuneration page css  */
    .remuneration-plan-wrapper ul.nav-tabs .nav-item .nav-link {
        font-size: 18px;
    }

    .remuneration-plan-wrapper ul.nav-tabs .nav-item:not(:last-child) {
        margin-right: 5px;
    }

    /* order not returnable modal css  */
    .cancel_order_modal.not-cancel ul li {
        margin: 0 10px;
    }
}

@media only screen and (max-width: 575px) {
    .header-middle .box button.pulse {
        top: 37px;
        right: -68px;
        width: 30px;
        height: 30px;
        z-index: 999;
        position: absolute;
    }

    .header-middle a.icon-search-three {
        right: -90px;
        top: 50px;
        line-height: 38px;
        z-index: 999;
    }

    .header-top .header-right {
        width: 100%;
    }

    /* alkaline page testimonial tab mobile css  */
    .panel-group .panel .plate-banner {
        background-image: none !important;
    }

    .panel-group .plate-banner .plate-banner-img img {
        width: 80%;
        margin: 0 auto;
        display: inline;
    }

    .plate_alkaline5 .product-details .table tr th {
        width: 200px;
    }

    /* phytowiz page responsive css */
    .phytowiz-work-inner ul li span.numbering {
        font-size: 20px;
    }

    .miracle-section {
        padding-bottom: 130px;
    }

    .miracle-content {
        left: -10px;
    }

    .miracle-content img {
        width: 80%;
    }

    .miracle-content span {
        top: 85px;
        font-size: 15px;
        left: 16px;
    }

    .miracle-content a.btn {
        margin-left: -40px;
    }


}

@media only screen and (max-width: 475px) {
    .product-wrapper-grid .col-6.col-md-3.col-sm-4 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .header-search-inline .form-control {
        min-width: 240px;
    }

    .lg-outer {
        padding-left: 15px;
        padding-right: 15px;
    }

    .product-wrapper-grid .col-6.col-md-4.col-sm-4 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .login_dp {
        border-radius: 3px;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 31%);
        height: auto;
        left: 100px;
        right: 15px;
        position: absolute;
        top: 5px;
        width: 276px;
        background-color: #fff;
        z-index: 999;
        display: block;
    }

    .price-list .off-price {
        display: block;
        width: 100%;
    }

    /* order not returnable modal css  */
    .cancel_order_modal.not-cancel ul {
        flex-direction: column;
    }

    .cancel_order_modal.not-cancel ul li {
        margin: 10px 0 0 0;
    }

    .cancel_order_modal.not-cancel h1 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .product-single-filter label {
        font-size: 12px;
        margin-right: 5px;
    }

    .product-single-filter .config-size-list li a {
        min-width: 22px;
        height: 22px;
        line-height: 22px;
        font-size: 12px;
    }

    .product-single-filter h3 {
        font-size: 12px;
        padding: 3px 8px;
    }

    /* search open menu css  */
    .search_drp ul li p.search-title {
        max-width: 140px;
    }

    body .search_drp {
        width: 340px !important;
    }
}

@media (max-width: 420px) {

    /* store location page css */
    .state-box .state-content .content-list li {
        flex-direction: column;
    }

    .state-box .state-content .content-list li+li {
        margin-top: 10px;
    }

    .state-box .state-content .content-list li label,
    .state-box .state-content .content-list li span {
        width: 100%;
    }

    /* banner text css  */
    .infoslider_with_icons .border-right-slider {
        text-align: center;
        flex-direction: column;
    }

    .btn {
        padding: 10px 30px;
    }

    .promo-section .right-content span {
        font-size: 14px;
    }

    .CategorySlider-content .category-content h3 {
        text-align: center;
        font-size: 14px;
    }

    .product-single-filter {
        flex-wrap: wrap;
    }

    .size_guide {
        width: 100%;
        margin: 10px 0 0 0;
    }

    /* cms page css  */
    .cms-page-title h2 {
        font-size: 20px;
    }

    .section-wrapper .listing-head h2,
    .cms-inner-wrapper .listing-content h4 {
        font-size: 18px;
    }

    .cms-inner-wrapper ul li,
    .cms-inner-wrapper ol li,
    .cms-wrapper .listing-content p,
    .blog-details-wrapper p,
    .blog-details-wrapper blockquote {
        font-size: 14px;
        line-height: normal;
    }

    .cms-inner-wrapper ul li:not(:last-child) {
        margin-bottom: 5px;
    }

    .download-box {
        margin-bottom: 15px;
        padding: 15px;
    }

    .download-box .download-icon {
        margin-top: -60px;
    }

    .download-box .download-icon i {
        margin-bottom: 10px;
    }

    .download-box .download-content h5 {
        font-size: 18px;
    }

    /* alkaline page css  */
    .box-title h3 {
        font-size: 26px;
    }

    /* cart page css  */
    .process_cart ul {
        justify-content: space-between;
    }

    .process_cart ul li a i {
        display: block;
        text-align: center;
    }

    /* wallet transaction list css  */
    .trans_details {
        align-items: flex-start;
    }

    .trans_details_left .refund {
        padding-left: 30px;
    }

    .bottom_inside {
        padding: 10px;
    }

    .refund_amt p {
        font-size: 16px;
    }

    /* notifiaction css  */
    .notifications_list ul li {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: baseline;
    }

    .notifications_list ul li span {
        margin: 0
    }
}

@media only screen and (max-width: 645px) {
    .infoslider_with_icons .info-box {
        border-right: none;
    }

    .checkout-progress-bar li>span {
        font-size: 14px;
    }

    h4.login_modal_title i.fab.fa-whatsapp {
        font-size: 19px;
        line-height: 32px;
        width: 34px;
        height: 34px;
    }

    h4.login_modal_title {
        font-size: 13px;
    }

    .infoslider_with_icons .border-right-slider {
        border-right: 0px;
    }

}

@media (max-width: 380px) {

    /* remuneration page css  */
    .formula-table tr td {
        font-size: 10px;
    }

    /* cart dropdown css  */
    .cart-dropdown .dropdown-menu,
    .compare-dropdown .dropdown-menu {
        width: 300px;
    }

    /* dashboard page css  */
    .inside_dash {
        padding: 10px;
    }

    .inside_dash i {
        font-size: 40px;
    }

    .inside_dash a p {
        font-size: 13px;
    }

    span.notification_count {
        right: -15px;
        top: -5px;
    }

    /* category page filter menu css  */
    .mobile-sidebar {
        width: 280px;
    }

    .sidebar-opened .sidebar-toggle {
        left: 280px;
    }

    /* w.up modal for login css  */
    .login_modal_phone,
    .register_modal_phone {
        width: auto;
    }
}

@media only screen and (max-width: 375px) {
    body .search_drp {
        width: 300px !important;
        left: -10px !important;
        transform: translateX(-55%);
        -webkit-transform: translateX(-55%);
    }
}

/* responsive-css-end */

.transaction-box {
    background-color: var(--bg-gray);
    padding: 20px;
}

.transaction-box li+li {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid var(--light-gray);
}

.transaction-box li {
    display: flex;
    align-items: center;
}

.transaction-box li label {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
}

.transaction-box li span {
    margin-left: auto;
}

.transaction-box li span.badge {
    font-size: 14px;
}