*{
    outline: none;
    text-decoration: none;
}
h1,h2,h3,h4,h5,h6,p{
    margin: 0px;
    padding: 0px;   
    text-transform: inherit;
}

.red_price{
    color: #999!important;
    margin-right: 10px;
    text-decoration: line-through;
    font-size: 14px!important;
}

.col-grid-box{
    margin-bottom: 20px;
}

.product-detail .name_and_icon i{
    cursor: pointer;
}

.pro_list_item a.incentive_point_ip{
    color: inherit!important;
}
 

.pro_list_item .category-list{
    color: #BD3042!important;
} 

.seen-it-cls{
    margin: 0 !important;
}

.div_align_center_both_direction{
    min-height: 400px;
    display: flex;
    align-items: center;
}

.div_align_center_both_direction .align_component{
    margin: 0 auto;
}